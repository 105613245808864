import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { UndoButton, SaveButton } from '../Buttons';
import TextInput from '../TextInput';
import { authTfa } from '../../actions/TfaActions';
import styles from './SetupTfa.module.css';

function SetupTfa({ open, otpUrl, manCode, onDiscard }) {
  const [code, setCode] = useState('');

  function onAuth() {
    authTfa(code.replace(/\D/g, ''));
  }

  return (
    <Dialog open={open}>
      <DialogTitle>Konfigurera tvåfaktorsautentisering</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div className={styles.section}>
            <h3 className={styles.title}>Steg 1</h3>
            <p>
              För att aktivera tvåfaktorsautentisering, så scannar du QR-koden
              nedan med din mobilapp. Om du inte har någon autentiseringsapp
              rekommenderar vi Google Authenticator eller Microsoft
              Authenticator.
            </p>
            <div className={styles.center}>
              <QRCode value={otpUrl} size={256} />
            </div>
          </div>
          <div>
            <h3 className={styles.title}>Steg 2</h3>
            <p>
              Efter att du scannat QR-koden, skriv in den sexsiffriga koden som
              din app genererat för att bekräfta konfigurationen. Om du har
              problem att scanna QR-koden kan du manuellt konfigurerea med koden{' '}
              <emp style={{ color: 'var(--black)' }}>{manCode}</emp>
            </p>
            <div className={styles.center}>
              <TextInput
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </div>
          </div>
        </DialogContentText>
        <DialogActions>
          <UndoButton onClick={onDiscard}>Ångra & stäng</UndoButton>
          <SaveButton onClick={onAuth}>Bekräfta kod</SaveButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default SetupTfa;
