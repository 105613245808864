import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import {
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
  InputLabel,
  IconButton,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { AddButton, UndoButton } from '../Buttons';
import Sidebar from '../Sidebar';
import TextInput from '../TextInput';
import MultiSelect from '../MultiSelect';
import PhoneField, { toI11 as formatNbr } from '../PhoneField';
import Loading from '../Loading';
import { createUser } from '../../actions/UserActions';
import { fetchSites } from '../../actions/SiteActions';
import { getPermissionGroups } from '../../actions/PermissionGroupActions';
import siteStore from '../../stores/SiteStore';
import permissionGroupStore from '../../stores/PermissionGroupStore';
import userStore from '../../stores/UserStore';
import { withStates } from '../../utils/with-state';
import PermissionGroupDialog from './PermissionGroupDialog';
import aStyles from './Add.module.css';
import cStyles from '../configuration/AddForm.module.css';

const styles = { ...cStyles, ...aStyles };

function AddUser({ permissionGroups, sites, currentSite, userRole }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNbr, setPhoneNbr] = useState('');
  const [role, setRole] = useState(userRole);
  const [permissionGroupId, setPermissionGroupId] = useState(null);
  const [selectedSites, selectSites] = useState([]);
  const [err, setErr] = useState({
    fname: {
      err: false,
      text: 'Måste vara mellan 1 och 255 tecken',
    },
    lname: {
      err: false,
      text: 'Måste vara mellan 1 och 255 tecken',
    },
    email: {
      err: false,
      text: 'Måste vara mellan 5 och 255 tecken',
    },
    role: {
      err: false,
      text: 'Måste vara valt',
    },
  });

  useEffect(() => {
    fetchSites(false);
    getPermissionGroups();
  }, []);

  useEffect(() => {
    const site = sites?.find((x) => x.id === currentSite);

    if (site) {
      selectSites([site]);
    }
  }, [sites, currentSite]);

  function checkError() {
    let newErr = { ...err };
    for (let id of Object.keys(err)) {
      let eVal;
      switch (id) {
        case 'fname':
          eVal = firstName.length < 1 || firstName.length > 255;
          break;
        case 'lname':
          eVal = lastName.length < 1 || lastName.length > 255;
          break;
        case 'email':
          eVal = email.length < 5 || email.length > 255;
          break;
        case 'role':
          eVal = !role?.length;
          break;
        default:
          break;
      }
      if (eVal !== null) {
        newErr[id].err = eVal;
      }
    }
    setErr(newErr);
    return Object.values(err).some((x) => x.err);
  }
  function handleChange(id, val) {
    switch (id) {
      case 'fname':
        setFirstName(val);
        break;
      case 'lname':
        setLastName(val);
        break;
      case 'email':
        setEmail(val);
        break;
      case 'role':
        setRole(val);
        break;
      case 'phoneNbr':
        setPhoneNbr(val);
        break;
      case 'permissionGroupId':
        setPermissionGroupId(val);
        break;
      default:
        break;
    }
    setErr({ ...err, [id]: { ...err[id], err: false } });
  }

  function doAddUser(event) {
    event.preventDefault();
    if (!checkError())
      createUser(
        email,
        firstName,
        lastName,
        role,
        formatNbr(phoneNbr),
        selectedSites.map((x) => x.id),
        permissionGroupId
      )
        .then(() => setRedirect(true))
        .catch((e) => setError(e));
  }

  if (!sites || !permissionGroups) return <Loading big center />;

  if (redirect) return <Redirect to="/admin/users" />;

  const currentPermissionGroup = permissionGroups.find(
    (x) => x.id === permissionGroupId
  );

  return (
    <div className={styles.container}>
      <div className={styles.containerInner}>
        <Sidebar
          title="Lägg till användare"
          text="Skapa en ny användare"
          backRoute="/admin/users"
        />
        {error && <p className={styles.error}>{error.message}</p>}
        <div className={styles.content}>
          <div className={styles.header}>
            <h2>Ny användare</h2>
            <UndoButton href="/admin/users">Ångra & stäng</UndoButton>
          </div>

          {sites && (
            <div className={styles.formContainer}>
              <form className={styles.form} onSubmit={doAddUser}>
                <TextInput
                  label="E-post"
                  value={email}
                  onChange={(e) => handleChange('email', e.target.value)}
                  error={err.email.err}
                  helperText={err.email.err ? err.email.text : null}
                />
                <div className={styles.selects}>
                  <TextInput
                    className={styles.select}
                    label="Förnamn"
                    value={firstName}
                    onChange={(e) => handleChange('fname', e.target.value)}
                    error={err.fname.err}
                    helperText={err.fname.err ? err.fname.text : null}
                  />
                  <TextInput
                    className={styles.select}
                    label="Efternamn"
                    value={lastName}
                    onChange={(e) => handleChange('lname', e.target.value)}
                    error={err.lname.err}
                    helperText={err.lname.err ? err.lname.text : null}
                  />
                </div>
                <div className={styles.selects}>
                  {userRole === 'admin' && (
                    <FormControl
                      variant="outlined"
                      className={styles.select}
                      margin="dense"
                      error={err.role.err}
                    >
                      <InputLabel>Roll</InputLabel>
                      <Select
                        label="Roll"
                        value={role}
                        onChange={(e) => handleChange('role', e.target.value)}
                        margin="dense"
                        disabled={role !== 'admin'}
                      >
                        <MenuItem value="customer">Kund</MenuItem>
                        <MenuItem value="admin">Admin</MenuItem>
                        <MenuItem value="employee">Anställd</MenuItem>
                      </Select>
                      {err.role.err && (
                        <FormHelperText>{err.role.text}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                  <FormControl
                    variant="outlined"
                    className={styles.select}
                    margin="dense"
                  >
                    <InputLabel>Behörighetsgrupp</InputLabel>
                    <Select
                      label="Behörighetsgrupp"
                      value={permissionGroupId}
                      onChange={(e) =>
                        handleChange('permissionGroupId', e.target.value)
                      }
                      margin="dense"
                    >
                      {permissionGroups.map((permissionGroup) => (
                        <MenuItem
                          key={permissionGroup.id}
                          value={permissionGroup.id}
                        >
                          {permissionGroup.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setDialogOpen(true);
                    }}
                    disabled={permissionGroupId === null}
                    className={styles.infoButton}
                    size="small"
                  >
                    <InfoIcon />
                  </IconButton>
                </div>

                <div>
                  <PhoneField
                    label="Telefonnummer"
                    value={phoneNbr}
                    onChange={(e) => handleChange('phoneNbr', e)}
                  />
                </div>

                {!currentSite && (
                  <MultiSelect
                    className={styles.select}
                    disabled={role === 'admin'}
                    label="Länkade anläggningar"
                    list={sites}
                    selectedValues={selectedSites}
                    selectValues={selectSites}
                    renderValue={(s) =>
                      `${s.length} Anläggning${s.length > 1 ? 'ar' : ''}`
                    }
                    searchable
                  />
                )}
                <div className={styles.footer}>
                  <AddButton type="submit">Lägg till användare</AddButton>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
      <PermissionGroupDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        permissionGroup={currentPermissionGroup}
      />
    </div>
  );
}

function siteMapper({ sites, currentSite }) {
  return { sites, currentSite };
}

function permissionGroupMapper({ permissionGroups }) {
  return { permissionGroups };
}

function userMapper({ role }) {
  return { userRole: role };
}

export default withStates(
  AddUser,
  [siteStore, permissionGroupStore, userStore],
  [siteMapper, permissionGroupMapper, userMapper]
);
