import axios from 'axios';
import userStore from '../stores/UserStore';
import { clearAllStores } from '../actions/ActionUtils';
import { createSnack } from '../actions/SnackActions';

const ROOT_URL = '/api';
const CANCEL_TOKENS = {};

export function getWsUrl(path, qs, includeToken = true) {
  let host;
  if (process.env.NODE_ENV === 'production') {
    host = window.location.host;
  } else {
    host = 'localhost:8080'; // Proxy solution
  }
  let qsstr = '';
  if (includeToken) {
    /* eslint-disable no-param-reassign */
    if (!qs) qs = {};
    qs.token = userStore.token;
    /* eslint-enable no-param-reassign */
  }

  if (qs) {
    qsstr += '?';
    let delim = '';
    /* eslint-disable-next-line guard-for-in, no-restricted-syntax */
    for (const k in qs) {
      qsstr += `${delim}${k}=${qs[k]}`;
      delim = '&';
    }
    qsstr = encodeURI(qsstr);
  }
  const protocol = process.env.NODE_ENV === 'production' ? 'wss' : 'ws';

  return `${protocol}://${host}${path}${qsstr}`;
}

export function request(params, includeToken = true, skipSnack = false) {
  const url = ROOT_URL + params.url;
  let headers = {};
  if (includeToken) {
    const { token } = userStore;
    headers = {
      ...(params.headers || {}),
      Authorization: `Bearer ${token}`,
    };
  }

  return axios({
    ...params,
    url,
    headers,
  }).catch((e) => {
    if (!axios.isCancel(e)) {
      const res = e.response;
      if (res?.status === 401) {
        createSnack('Du har blivit utloggad. Var god logga in igen');
        clearAllStores();
      } else if (res?.status === 400 || res?.status === 403) {
        const errMsg = res?.data?.error?.details;

        if (typeof errMsg === 'string') createSnack(`Fel: ${errMsg}`);
        else createSnack('Ett fel uppstod');
      } else if (!skipSnack) {
        createSnack('Ett fel uppstod, vänligen försök igen');
      }
      throw e;
    }
  });
}

export function get(
  url,
  { includeToken, skipSnack, ...params } = {},
  cancelToken = null
) {
  return request(
    {
      url,
      method: 'GET',
      cancelToken,
      ...params,
    },
    includeToken,
    skipSnack
  );
}

export function post(url, data, { includeToken, skipSnack, ...params } = {}) {
  return request(
    {
      url,
      data,
      method: 'POST',
      ...params,
    },
    includeToken,
    skipSnack
  );
}

export function put(url, data, { includeToken, skipSnack, ...params } = {}) {
  return request(
    {
      url,
      data,
      method: 'PUT',
      ...params,
    },
    includeToken,
    skipSnack
  );
}

export function patch(url, data, { includeToken, skipSnack, ...params } = {}) {
  return request(
    {
      url,
      data,
      method: 'PATCH',
      ...params,
    },
    includeToken,
    skipSnack
  );
}

export function del(url, data, { includeToken, skipSnack, ...params } = {}) {
  return request(
    {
      url,
      data,
      method: 'DELETE',
      ...params,
    },
    includeToken,
    skipSnack
  );
}

export function addCtCheck(key) {
  const t = axios.CancelToken.source();
  CANCEL_TOKENS[key] = t;
  return t.token;
}

export function ctExists(key) {
  return key in CANCEL_TOKENS;
}

export function cancelCalls(key) {
  let ct = CANCEL_TOKENS?.[key];
  if (!ct) return false;
  ct.cancel(`${key}: anrop avbrutet`);
  delete CANCEL_TOKENS[key];
  return true;
}
