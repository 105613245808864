import React from 'react';
import Header from '../Header';
import SubHeader from '../SubHeader';
import userStore from '../../stores/UserStore';
import withState from '../../utils/with-state';

function AdminHeader({ role, ...props }) {
  const adminLinks = [
    {
      to: '/admin/sites',
      title: 'Anläggningar',
    },
    {
      to: '/admin/users',
      title: 'Användare',
    },
    {
      to: '/admin/holidays',
      title: 'Röda dagar',
    },
    {
      to: '/admin/permission-groups',
      title: 'Behörighetsgrupper',
    },
    {
      to: '/admin/vms',
      title: 'VMS',
    },
    {
      to: '/admin/alarm-tags',
      title: 'Larmtaggar',
    },
    {
      to: '/admin/documents',
      title: 'Dokument',
    },
  ];

  const customerLinks = [
    {
      to: '/admin/users',
      title: 'Användare',
    },
  ];

  return (
    <>
      <Header activeTab="admin" showSiteSelector={false} />
      <SubHeader
        links={role === 'admin' ? adminLinks : customerLinks}
        {...props}
      />
    </>
  );
}

export default withState(AdminHeader, userStore, ({ role }) => ({ role }));
