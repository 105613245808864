import React, { useEffect, useState } from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import { AddButton } from '../../components/Buttons';
import SetupTfa from '../../components/settings/SetupTfa';
import DeleteTfa from '../../components/settings/DeleteTfa';
import tfaStore from '../../stores/TfaStore';
import { setupTfa } from '../../actions/TfaActions';
import withState from '../../utils/with-state';
import styles from './TFASettings.module.css';

function TFASettings({ otpUrl, usesTfa, manCode }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (otpUrl !== '') setOpen(true);
  }, [otpUrl]);

  if (usesTfa) {
    return <DeleteTfa />;
  }

  return (
    <>
      <p className={styles.info}>
        Konfigurera tvåfaktorsautentisering för att skydda ditt konto med ett
        extra säkerhetslager.
      </p>
      {!usesTfa && (
        <div className={styles.notSetup}>
          <WarningIcon className={styles.warningIcon} />
          <span className={styles.notSetupText}>
            Tvåfaktorsautentisering ännu ej konfigurerat.
          </span>
        </div>
      )}
      <div className={styles.button}>
        <AddButton
          onClick={() => {
            setupTfa();
            setOpen(true);
          }}
        >
          Konfigurera 2FA
        </AddButton>
      </div>
      <SetupTfa
        open={open}
        otpUrl={otpUrl}
        manCode={manCode}
        onDiscard={() => setOpen(false)}
      />
    </>
  );
}

export default withState(TFASettings, tfaStore, (state) => ({
  otpUrl: state.otpUrl,
  usesTfa: state.usesTfa,
  manCode: state.manCode,
}));
