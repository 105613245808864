import React, { useState, useEffect } from 'react';
import { useDebounce } from 'react-use';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import { IconButton } from '@material-ui/core';
import UserIcon from '@material-ui/icons/AccountBox';
import { AddButton, UndoButton } from '../Buttons';
import Loading from '../Loading';
import { toI11 as phoneFormat } from '../PhoneField';
import Sidebar from '../Sidebar';
import Table from '../Table';
import userListStore from '../../stores/UserListStore';
import userStore from '../../stores/UserStore';
import siteStore from '../../stores/SiteStore';
import { fetchUsers } from '../../actions/UserListActions';
import { trimStr } from '../../utils/generation';
import { withStates } from '../../utils/with-state';
import { useHasPermissions, PERMISSIONS } from '../../utils/permissions';
import aStyles from './AdminList.module.css';
import moStyles from '../mouseOver.module.css';
import SiteSelector from '../SiteSelector';

const styles = { ...aStyles, ...moStyles };

function truncateEllipsis(str, length) {
  if (str.length > length) {
    return `${str.substring(0, length)}...`;
  }
  return str;
}

function UserList({ users, loading, role, currentSite }) {
  const [debouncedCurrentSite, setDebouncedCurrentSite] = useState(currentSite);

  const canEdit = useHasPermissions(PERMISSIONS.USERS_UPDATE);

  useDebounce(
    () => {
      setDebouncedCurrentSite(currentSite);
    },
    50,
    [currentSite]
  );

  useEffect(() => {
    if (debouncedCurrentSite === 0 && role !== 'admin') {
      return;
    }

    fetchUsers(debouncedCurrentSite);
  }, [role, debouncedCurrentSite]);

  const data = (users || []).map((user) => ({
    Namn: trimStr(`${user.lastName}, ${user.firstName}`),
    'E-Post': trimStr(user.email),
    Anläggning: user?.sitenames
      ? truncateEllipsis(user.sitenames, 50)
      : 'Utan anläggning',
    Roll: user.role,
    Behörighetsgrupp: user?.permissionGroup?.name || 'Ej angivet',
    'Tel. nummer': user?.phoneNbr ? phoneFormat(user.phoneNbr) : 'Ej angivet',
    ' ': (
      <div className={styles.mouseOver}>
        <Link to={`/admin/users/${user.id}`} style={{ textDecoration: 'none' }}>
          <IconButton>
            <VisibilityIcon />
            <p className={styles.mouseOverText}>
              {canEdit ? 'Titta & redigera' : 'Titta'}
            </p>
          </IconButton>
        </Link>
      </div>
    ),
  }));

  return (
    <div className={styles.container}>
      <div className={styles.containerInner}>
        <Sidebar
          title="Användare"
          text="Översikt Safe Solutions AB:s användare"
          Icon={<UserIcon fontSize="large" />}
        />

        <div className={styles.content}>
          <div className={styles.header}>
            <h2>Alla användare</h2>
            <SiteSelector isDashboard includeAllSites={role === 'admin'} />
            <div className={styles.buttons}>
              {role === 'admin' && (
                <UndoButton href="/admin/users/push-notifs">
                  Skicka push-notis
                </UndoButton>
              )}
              <AddButton href="/admin/users/new">Ny användare</AddButton>
            </div>
          </div>
          {loading ? (
            <Loading big center />
          ) : (
            <div className={styles.listContainer}>
              <Table
                data={data}
                showSearch
                searchPlaceholder="Sök efter användare"
                rowClass={styles.row}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const userListMapper = ({ users, isFetching }) => ({
  users,
  loading: isFetching,
});

const userMapper = ({ role }) => ({
  role,
});

const siteMapper = ({ currentSite }) => ({
  currentSite,
});

export default withStates(
  UserList,
  [userListStore, userStore, siteStore],
  [userListMapper, userMapper, siteMapper]
);
