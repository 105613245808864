import alarmS from '../stores/AlarmsStore';
import alarmTagS from '../stores/AlarmTagsStore';
import authPeopleS from '../stores/AuthPeopleStore';
import camerasS from '../stores/CamerasStore';
import cameraStatusS from '../stores/CameraStatusStore';
import cameraS from '../stores/CameraStore';
import dashboardS from '../stores/DashboardStatsStore';
import exceptionS from '../stores/ExceptionStore';
import holidayS from '../stores/HolidayStore';
import instrS from '../stores/InstructionsStore';
import maskS from '../stores/MaskStore';
import motionDetS from '../stores/MotionDetectionsStore';
import patrolS from '../stores/PatrolStore';
import pocS from '../stores/PocsStore';
import recordingS from '../stores/RecordingsStore';
import permissionGroupS from '../stores/PermissionGroupStore';
import scheduleS from '../stores/ScheduleStore';
import siteMediaS from '../stores/SiteMediaStore';
import siteS from '../stores/SiteStore';
import snackS from '../stores/SnackStore';
import tfaS from '../stores/TfaStore';
import timeoutS from '../stores/TimeoutStore';
import userListS from '../stores/UserListStore';
import userS from '../stores/UserStore';

/* eslint-disable-next-line import/prefer-default-export */
export function clearAllStores() {
  [
    alarmS,
    alarmTagS,
    authPeopleS,
    camerasS,
    cameraStatusS,
    cameraS,
    dashboardS,
    exceptionS,
    holidayS,
    instrS,
    maskS,
    motionDetS,
    patrolS,
    pocS,
    recordingS,
    permissionGroupS,
    scheduleS,
    siteMediaS,
    siteS,
    snackS,
    tfaS,
    timeoutS,
    userListS,
    userS,
  ].forEach((store) => {
    store.clear();
  });
  userS.unsetToken();
  siteS.unsetCurrentSite();
}
