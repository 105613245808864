import React from 'react';
import VerifyTfa from './VerifyTfa';
import { deleteTfa } from '../../actions/TfaActions';

function DeleteTfa() {
  return (
    <>
      <h1>Ta bort Tvåfaktorsautentisering</h1>
      <div>
        <p>
          Bekräfta att du vill ta bort din tvåfaktorsautentisering genom att
          mata in koden i din app
        </p>
        <VerifyTfa onVerify={deleteTfa} />
      </div>
    </>
  );
}

export default DeleteTfa;
