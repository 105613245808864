import createStore from '../utils/create-store';

const TOKEN_IDENTIFIER = 'safe-token';

const _UserStore = createStore({
  token: localStorage.getItem(TOKEN_IDENTIFIER),
  userInfo: null,
  role: 'customer',
});

export class UserStore extends _UserStore {
  setToken(token) {
    if (token) {
      this.token$.next(token);
      localStorage.setItem(TOKEN_IDENTIFIER, token);
    }
  }

  unsetToken() {
    this.token$.next(null);
    localStorage.removeItem(TOKEN_IDENTIFIER);
  }
}

export default new UserStore();
