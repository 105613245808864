import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  ListSubheader,
  TextField,
} from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Search } from '@material-ui/icons';

const THEME = createTheme({
  typography: { fontFamily: 'MaisonNeue' },
  palette: {
    secondary: {
      main: '#e30613',
    },
  },
});

function Searchbar({ val, setVal }) {
  return (
    <TextField
      value={val}
      onKeyDown={(e) => e.stopPropagation()}
      onChange={(e) => setVal(e.target.value)}
      InputProps={{ startAdornment: <Search /> }}
      autoFocus
      fullWidth
      placeholder="Sök"
    />
  );
}

function MultiSelect({
  label,
  renderValue = (sel) =>
    sel?.length ? `${sel.length} vald${sel.length > 1 ? 'a' : ''}` : '',
  list,
  selectedValues,
  selectValues,
  displayId = 'name',
  emptyText = 'Tomt',
  searchable = false,
  isElementArray = false,
  className,
  ...props
}) {
  /**
   *  displayId - what element should be written out in the list
   *  isElementArray - list and selectedValues are primitive data arrays, not lists of objects.
   *      Thus, displayId will be ignored for filtering and displaying
   */
  const [query, setQuery] = useState('');

  function handleChange(e) {
    selectValues(e.target.value);
  }

  const filtered = (list || []).filter((s) =>
    ((isElementArray ? s : s?.[displayId]) || null)
      .toLowerCase()
      .includes(query.toLowerCase())
  );

  return (
    <ThemeProvider theme={THEME}>
      <FormControl style={{ width: '100%' }} variant="outlined" margin="dense">
        <InputLabel htmlFor="outlined-label">{label}</InputLabel>
        <Select
          className={className}
          label={label}
          multiple
          value={selectedValues}
          onChange={handleChange}
          renderValue={renderValue}
          inputProps={{
            name: 'label',
            id: `outlined-label-${label}`,
          }}
          onClose={() => setQuery('')}
          MenuProps={{ variant: 'menu' }}
          {...props}
        >
          {searchable && (
            <div>
              <Searchbar val={query} setVal={setQuery} />
            </div>
          )}
          {filtered?.length === 0 && (
            <MenuItem key="default" value="">
              <ListItemText primary={emptyText} />
            </MenuItem>
          )}

          {filtered.map((entry, idx) => {
            if (entry?.isGroupLabel) {
              return (
                <ListSubheader key={`sub-${idx}`} value={null}>
                  {isElementArray ? entry : entry[displayId]}
                </ListSubheader>
              );
            }
            return (
              <MenuItem key={idx} value={entry}>
                <Checkbox checked={selectedValues.indexOf(entry) > -1} />
                <ListItemText
                  primary={isElementArray ? entry : entry[displayId]}
                />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
}

export default MultiSelect;
