import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import PermissionGroup from './PermissionGroup';
import Sidebar from '../../Sidebar';
import Loading from '../../Loading';
import { UndoButton } from '../../Buttons';
import permissionGroupStore from '../../../stores/PermissionGroupStore';
import withState from '../../../utils/with-state';
import {
  updatePermissionGroup,
  getPermissionGroup,
  getPermissionGroups,
  getPermissions,
  clearPermissionGroup,
} from '../../../actions/PermissionGroupActions';
import styles from '../../configuration/EditForm.module.css';

function EditPermissionGroup({ permissionGroup, allPermissions, match }) {
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    getPermissions();
  }, []);

  const clearError = () => setError(null);
  useEffect(() => {
    const id = match?.params?.id;
    if (id) getPermissionGroup(id);
  }, [match]);

  function onSavePermissionGroup({ name, permissions, isAdminExclusive }) {
    if (permissionGroup && permissionGroup.id) {
      updatePermissionGroup({
        id: permissionGroup.id,
        name,
        permissions,
        isAdminExclusive,
      })
        .then(() => {
          clearPermissionGroup();
          getPermissionGroups();
          setRedirect(true);
        })
        .catch((e) => setError(e));
    }
  }

  if (redirect) return <Redirect to="/admin/permission-groups" />;

  const permissionGroupId = parseInt(match?.params?.id || '-1', 10);

  if (!permissionGroup || permissionGroup.id !== permissionGroupId)
    return <Loading big center />;

  return (
    <div className={styles.container}>
      <div className={styles.containerInner}>
        <Sidebar
          title="Justera din behörighetsgrupp"
          text="Uppdatera din valda behörighetsgrupp här"
          Icon={<EventBusyIcon fontSize="large" />}
        />
        {permissionGroup && (
          <div className={styles.content}>
            <div className={styles.header}>
              <h2>Hantera behörighetsgrupp</h2>
              <UndoButton href="/admin/permission-groups">
                Ångra & stäng
              </UndoButton>
            </div>
            <div className={styles.form}>
              {error && (
                <p className={styles.error}>
                  {error?.response?.data?.error?.details?.[0]?.message ||
                    'Något gick fel'}
                </p>
              )}
              <PermissionGroup
                permissions={allPermissions}
                permissionGroup={permissionGroup}
                onSubmit={onSavePermissionGroup}
                clearError={clearError}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default withState(
  EditPermissionGroup,
  permissionGroupStore,
  ({ permissionGroup, permissions }) => ({
    permissionGroup,
    allPermissions: permissions,
  })
);
