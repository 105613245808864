import siteStore from '../stores/SiteStore';
import { createSnack } from './SnackActions';
import { get, post, patch, del } from '../utils/api';

export function getSiteInfo(id) {
  siteStore.unsetCurrentSite();
  if (!id) return Promise.reject();
  return get(`/sites/${id}`).then((res) => {
    siteStore.siteInfo = res.data;
    siteStore.setCurrentSite(res.data.id);
  });
}

export function addSiteAction(name, info, address, retainDays) {
  return post('/sites', { name, info, address, retainDays })
    .then(() => createSnack('Anläggning skapad'))
    .catch(() => createSnack('Kunde inte skapa anläggning'));
}

export function editSiteInfo(id, info, address, retainDays, location, name) {
  return patch('/sites', {
    id,
    info,
    address,
    retainDays: retainDays || 30,
    location,
    name,
  }).then(() => createSnack('Anläggning uppdaterad'));
}

export function clearSiteInfo() {
  siteStore.siteInfo = null;
}

/* eslint-disable-next-line import/prefer-default-export */
export function setCurrentSite(site) {
  return siteStore.setCurrentSite(site);
}

export function unsetCurrentSite() {
  return siteStore.unsetCurrentSite();
}

/* selectFirst=false used in adminDashboard to keep currentSite=0 */
export function fetchSites(selectFirst = true) {
  siteStore.isFetching = true;
  return get('/sites')
    .then((res) => {
      siteStore.sites = res?.data?.sites || [];
      if (!siteStore.currentSite && selectFirst) {
        siteStore.setCurrentSite(res?.data?.sites?.[0]?.id);
      }
    })
    .finally(() => {
      siteStore.isFetching = false;
    });
}

export function deleteSite(id) {
  return del(`/sites/${id}`)
    .then(() => {
      createSnack('Anläggning borttagen');
      if (id === siteStore.currentSite) {
        unsetCurrentSite();
        fetchSites();
      }
    })
    .catch(() => createSnack('Kunde inte radera anläggning'));
}
