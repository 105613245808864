import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@material-ui/core';
import AppIcon from '@material-ui/icons/Apps';
import { get } from '../../utils/api';
import Sidebar from '../Sidebar';
import userStore from '../../stores/UserStore';
import styles from './VMS.module.css';

const BUCKET = 'safe-binaries';
function VMS() {
  const [version, setVersion] = useState('');
  const [fileType, setFileType] = useState('');
  const [versions, setVersions] = useState(null);
  useEffect(() => {
    (async () => {
      const res = await get('/vms/versions', {
        params: {
          bucket: BUCKET,
          token: userStore.token$.value,
        },
      });
      const { versions: _versions } = res?.data || {};
      setVersions(_versions);
    })();
  }, []);
  const downloadUrl = encodeURI(
    `/api/vms/download?bucket=${BUCKET}&token=${userStore.token$.value}&fileType=${fileType}&version=${version}`
  );

  function onDownloadClick(e) {
    if (!fileType || !version) {
      e.preventDefault();
    }
  }
  return (
    <div className={styles.container}>
      <div className={styles.containerInner}>
        <Sidebar
          title="VMS"
          text="Ladda ner VMS klienten"
          Icon={<AppIcon fontSize="large" />}
        />

        <div className={styles.content}>
          <div className={styles.header}>
            <h2>Ladda ner VMS</h2>
          </div>
          <div className={styles.innerContent}>
            <FormControl className={styles.formControl}>
              <InputLabel id="vms-version-label">Version</InputLabel>
              <Select
                labelId="vms-version-label"
                id="vms-version-select"
                value={version}
                onChange={(e) => setVersion(e.target.value)}
              >
                {(versions || []).map((v) => (
                  <MenuItem key={v} value={v}>
                    {v}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={styles.formControl}>
              <InputLabel id="vms-fileType-label">OS</InputLabel>
              <Select
                labelId="vms-fileType-label"
                id="vms-fileType-select"
                value={fileType}
                onChange={(e) => setFileType(e.target.value)}
              >
                <MenuItem value="exe">Windows (exe)</MenuItem>
                <MenuItem value="dmg">OS X (dmg)</MenuItem>
                <MenuItem value="AppImage">Linux (AppImage)</MenuItem>
              </Select>
            </FormControl>
            <a
              className={styles.link}
              onClick={onDownloadClick}
              target="_blank"
              rel="noopener noreferrer"
              href={downloadUrl}
            >
              <Button
                variant="outlined"
                className={styles.downloadButton}
                disabled={!version || !fileType}
              >
                Ladda ner
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VMS;
