import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { requestNewPass } from '../actions/UserActions';
import TextInput from './TextInput';
import { SaveButton } from './Buttons';
import styles from './ForgotPass.module.css';

import { ReactComponent as Logo } from '../images/wings.svg';
import { ReactComponent as EyeIllustration } from '../images/safe_eye_dark.svg';

function ForgotPass() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const history = useHistory();

  function goBack() {
    history.push('/');
  }

  function sendPassRequest(e) {
    e.preventDefault();
    requestNewPass({ email })
      .then(() => {
        goBack();
      })
      .catch((err) => {
        setError(err);
      });
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Logo
          className={styles.logo}
          title="Safes logga, tryck för att gå till inloggning"
          onClick={goBack}
        />
        <h1>Glömt ditt lösenord?</h1>
        {error && <p>{error.message}</p>}
        <h3 className={styles.subtitle}>
          Skriv in din e-postadress som du angav vid registrering, så skickar vi
          en återställningslänk.
        </h3>
        <form onSubmit={sendPassRequest} className={styles.form}>
          <h2 className={styles.emailTitle}>E-postadress</h2>
          <TextInput
            placeholder="namn@email.com"
            className={styles.emailInput}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className={styles.buttonContainer}>
            <SaveButton type="submit" disabled={!email.length}>
              Återställ Lösenord
            </SaveButton>
          </div>
        </form>
        <EyeIllustration className={styles.bigEye} title="Öga illustration" />
      </div>
      <div className={styles.illustration} />
    </div>
  );
}
export default ForgotPass;
