import moment from 'moment';
import recordingsStore from '../stores/RecordingsStore';
import { get, post } from '../utils/api';
import { createSnack } from './SnackActions';

export function getDatesWithRecordings(cameraId, channel) {
  const timezone = window.Intl.DateTimeFormat().resolvedOptions().timeZone;

  return get('/recording-dates', {
    skipSnack: true,
    params: {
      cameraId,
      channel,
      timezone,
    },
  })
    .then((res) => {
      recordingsStore.datesWithRecordings = res?.data?.dates || [];
    })
    .catch(() => {
      recordingsStore.datesWithRecordings = [];
      createSnack('Kunde inte hämta datum med inspelningar');
    });
}

export function getSegments(cameraId, channel, date) {
  const timezone = window.Intl.DateTimeFormat().resolvedOptions().timeZone;

  return get('/recordings', {
    params: {
      cameraId,
      channel,
      date,
      timezone,
    },
  })
    .then((res) => {
      const { bucket, segmentKeys } = res.data;

      recordingsStore.bucket = bucket;
      recordingsStore.segments = segmentKeys;
    })
    .catch(() => createSnack('Kunde inte hämta inspelningar'));
}

function createRecordingFilename(camera, channel, timeFromMs, timeToMs) {
  const format = (s) =>
    s
      .replace(/[^a-z0-9]/gi, '_')
      .toLowerCase()
      .slice(0, 10);

  const from = moment(timeFromMs, 'x').format();
  const to = moment(timeToMs, 'x').format();

  return `export_${camera.id}_${format(channel)}_${format(
    camera.name
  )}_${from}_${to}.mp4`;
}

export async function getRecordingExport(
  camera,
  channel,
  timeFromMs,
  timeToMs,
  reason
) {
  if (recordingsStore.exportingVideo) {
    return;
  }

  try {
    recordingsStore.exportingVideo = true;
    const response = await get('/recording-export', {
      params: {
        cameraId: camera.id,
        channel,
        timeFromMs,
        timeToMs,
        reason,
      },
      responseType: 'blob',
    });

    const a = document.createElement('a');

    a.href = URL.createObjectURL(response.data);
    a.download = createRecordingFilename(camera, channel, timeFromMs, timeToMs);
    a.click();

    recordingsStore.exportingVideo = false;
  } catch (e) {
    recordingsStore.exportingVideo = false;

    createSnack('Kunde inte exportera video');
  }
}

export async function createTemporaryLink(
  camera,
  channel,
  timeFromMs,
  timeToMs,
  reason
) {
  try {
    recordingsStore.exportingVideo = true;
    const response = await post('/temporary-export', {
      cameraId: camera.id,
      channel,
      timeFromMs,
      timeToMs,
      reason,
    });

    const l = window.location;

    recordingsStore.temporaryLink = `${l.protocol}//${l.hostname}${
      l.port ? `:${l.port}` : ''
    }${response.data.path}`;
  } catch (e) {
    createSnack('Kunde inte exportera video');
  }

  recordingsStore.exportingVideo = false;
}

export function dismissTemporaryLink() {
  recordingsStore.temporaryLink = null;
}

export function clearDatesAndSegments() {
  recordingsStore.datesWithRecordings = null;
  recordingsStore.segments = [];
}

export function clearSegments() {
  recordingsStore.segments = [];
}
