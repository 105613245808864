import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import AlarmTag from './AlarmTag';
import Sidebar from '../../Sidebar';
import { UndoButton } from '../../Buttons';
import {
  getAlarmTags,
  updateAlarmTag,
} from '../../../actions/AlarmTagsActions';
import styles from '../../configuration/EditForm.module.css';

function EditAlarmTag({ alarmTag }) {
  const [redirect, setRedirect] = useState(false);

  function onSaveAlarmTag({ name, color, archived }) {
    if (alarmTag?.id) {
      updateAlarmTag(alarmTag.id, {
        name,
        color,
        archived,
      }).then(() => {
        getAlarmTags();
        setRedirect(true);
      });
    }
  }

  if (redirect) return <Redirect to="/admin/alarm-tags" />;

  return (
    <div className={styles.container}>
      <div className={styles.containerInner}>
        <Sidebar
          title="Justera din larmtagg"
          text="Uppdatera din valda larmtagg här"
          Icon={<EventBusyIcon fontSize="large" />}
        />
        {alarmTag ? (
          <div className={styles.content}>
            <div className={styles.header}>
              <h2>Larmtaggar</h2>
              <UndoButton href="/admin/alarm-tags">Ångra & stäng</UndoButton>
            </div>
            <div className={styles.form}>
              <AlarmTag alarmTag={alarmTag} onSubmit={onSaveAlarmTag} />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default EditAlarmTag;
