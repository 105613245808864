import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import HolidayList from './HolidayList';
import Sidebar from '../../Sidebar';
import Loading from '../../Loading';
import { SaveButton, AddButton } from '../../Buttons';
import { getHolidays, clearHoliday } from '../../../actions/HolidayActions';
import holidayStore from '../../../stores/HolidayStore';
import withState from '../../../utils/with-state';
import styles from '../../configuration/Configuration.module.css';

function EmptyContainer() {
  return (
    <>
      <Sidebar
        title="Se över dina helgdagar här"
        text="Skapa dina helgdagar för alla årets dagar här"
        Icon={<EventBusyIcon fontSize="large" />}
      />
      <div className={styles.content}>
        <div className={styles.header}>
          <h3>Alla undantag</h3>
        </div>
        <div className={styles.empty}>
          <h2 className={styles.emptyTitle}>
            Du har för närvarande inga inlagda undantag
          </h2>
          <div className={styles.iconCircle}>
            <EventBusyIcon className={styles.emptyIcon} />
          </div>
          <div>
            <SaveButton href="/admin/holidays/new">Skapa helgdag</SaveButton>
          </div>
        </div>
      </div>
    </>
  );
}

function HolidayConfiguration({ holidays }) {
  const [edit, setEdit] = useState(null);

  useEffect(() => {
    clearHoliday();
    setEdit(null);
    getHolidays();
  }, []);

  if (!holidays) return <Loading big center />;

  if (edit) return <Redirect to={`/admin/holidays/${edit}`} />;

  return (
    <div className={styles.container}>
      <div className={styles.containerInner}>
        {holidays?.length === 0 && <EmptyContainer />}

        {holidays?.length > 0 && (
          <>
            <Sidebar
              title="Organisera och skapa röda dagar"
              text=""
              Icon={<EventBusyIcon fontSize="large" />}
            />
            <div className={styles.content}>
              <div className={styles.header}>
                <h3>Alla Röda dagar</h3>
                <AddButton href="/admin/holidays/new">Skapa ny</AddButton>
              </div>
              <div className={styles.paper}>
                <HolidayList
                  holidays={holidays || []}
                  selected={edit}
                  onSelect={setEdit}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default withState(HolidayConfiguration, holidayStore, (state) => ({
  holidays: state.holidays,
}));
