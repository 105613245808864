import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AdminHeader from '../components/admin/AdminHeader';
import SiteList from '../components/admin/SiteList';
import UserList from '../components/admin/UserList';
import Site from '../components/admin/Site';
import User from '../components/admin/User';
import HolidayConfiguration from '../components/admin/holidays/HolidayConfiguration';
import PermissionGroupConfiguration from '../components/admin/permission-groups/PermissionGroupConfiguration';
import AddSite from '../components/admin/AddSite';
import AddUser from '../components/admin/AddUser';
import AddHoliday from '../components/admin/holidays/AddHoliday';
import EditHoliday from '../components/admin/holidays/EditHoliday';
import AddPermissionGroup from '../components/admin/permission-groups/AddPermissionGroup';
import EditPermissionGroup from '../components/admin/permission-groups/EditPermissionGroup';
import VMS from '../components/admin/VMS';
import AlarmTags from '../components/admin/alarm-tags/AlarmTags';
import WritePushNotification from '../components/admin/WritePushNotification';
import LoggedInWrapper from '../components/LoggedInWrapper';
import { setCurrentSite } from '../actions/SiteActions';
import userStore from '../stores/UserStore';
import withState from '../utils/with-state';
import DocumentConfiguration from '../components/admin/documents/DocumentConfiguration';
import AddDocument from '../components/admin/documents/AddDocument';
import EditDocument from '../components/admin/documents/EditDocument';

function Admin({ role }) {
  useEffect(() => {
    if (role === 'admin') {
      setCurrentSite(0);
    }
  }, [role]);

  return (
    <>
      <AdminHeader showSiteSelector={false} />
      <Switch>
        <Redirect exact from="/admin" to="/admin/users" />
        <Route exact path="/admin/sites/new">
          <LoggedInWrapper Component={AddSite} admin />
        </Route>
        <Route exact path="/admin/users/new">
          <AddUser />
        </Route>
        <Route exact path="/admin/holidays/new">
          <LoggedInWrapper Component={AddHoliday} admin />
        </Route>
        <Route exact path="/admin/documents/new">
          <LoggedInWrapper Component={AddDocument} admin />
        </Route>
        <Route exact path="/admin/permission-groups/new">
          <LoggedInWrapper Component={AddPermissionGroup} admin />
        </Route>
        <Route
          path="/admin/sites/:id"
          render={(props) => (
            <LoggedInWrapper Component={Site} admin {...props} />
          )}
        />
        <Route path="/admin/sites">
          <LoggedInWrapper Component={SiteList} admin />
        </Route>
        <Route exact path="/admin/users/push-notifs">
          <LoggedInWrapper Component={WritePushNotification} admin />
        </Route>
        <Route path="/admin/users/:id" component={User} />
        <Route path="/admin/users">
          <UserList />
        </Route>
        <Route
          path="/admin/holidays/:id"
          render={(props) => (
            <LoggedInWrapper Component={EditHoliday} admin {...props} />
          )}
        />
        <Route
          path="/admin/documents/:id"
          render={(props) => (
            <LoggedInWrapper Component={EditDocument} admin {...props} />
          )}
        />
        <Route path="/admin/holidays">
          <LoggedInWrapper Component={HolidayConfiguration} admin />
        </Route>
        <Route
          path="/admin/permission-groups/:id"
          render={(props) => (
            <LoggedInWrapper Component={EditPermissionGroup} admin {...props} />
          )}
        />
        <Route path="/admin/permission-groups">
          <LoggedInWrapper Component={PermissionGroupConfiguration} admin />
        </Route>
        <Route path="/admin/vms">
          <LoggedInWrapper Component={VMS} admin />
        </Route>
        <Route path="/admin/alarm-tags">
          <LoggedInWrapper Component={AlarmTags} admin />
        </Route>
        <Route path="/admin/documents">
          <LoggedInWrapper Component={DocumentConfiguration} admin />
        </Route>
      </Switch>
    </>
  );
}

export default withState(Admin, userStore, ({ role }) => ({ role }));
