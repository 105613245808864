import React, { PureComponent } from 'react';

export const isEmpty = (o) =>
  Object.keys(o).length === 0 && o.constructor === Object;

export default function withState(WrappedComponent, store, mapper) {
  class EnhancedComponent extends PureComponent {
    state = mapper(store.value);

    componentDidMount() {
      this.subscription = store.data$.subscribe((data) => {
        this.setState(mapper(data));
      });
    }

    componentWillUnmount() {
      this.subscription.unsubscribe();
    }

    render() {
      return <WrappedComponent {...this.props} {...this.state} />;
    }
  }

  const wrappedName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  EnhancedComponent.displayName = `withState(${wrappedName}, ${store.constructor.name}, ${mapper.name})`;

  return EnhancedComponent;
}

export function withStates(WrappedComponent, stores, mappers) {
  let EnhancedComponent = WrappedComponent;

  stores.forEach((store, i) => {
    const mapper = mappers[i];
    EnhancedComponent = withState(EnhancedComponent, store, mapper);
  });

  return EnhancedComponent;
}
