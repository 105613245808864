import React, { useState } from 'react';
import moment from 'moment';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import { Link } from 'react-router-dom';
import { DeleteOutline } from '@material-ui/icons';
import ClockIcon from '@material-ui/icons/QueryBuilder';
import CalendarIcon from '@material-ui/icons/Today';
import { IconButton } from '@material-ui/core';
import ConfirmDialog from '../../configuration/ConfirmDialog';
import Table from '../../Table';
import { getHolidays, deleteHoliday } from '../../../actions/HolidayActions';
import { trimStr } from '../../../utils/generation';
import aStyles from '../../configuration/List.module.css';
import moStyles from '../../mouseOver.module.css';

const styles = { ...aStyles, ...moStyles };

moment.updateLocale('sv', {
  invalidDate: 'Ogiltigt datum',
  months: [
    'Jan',
    'Feb',
    'Mars',
    'April',
    'Maj',
    'Juni',
    'Juli',
    'Aug',
    'Sept',
    'Okt',
    'Nov',
    'Dec',
  ],
});

function HolidayList({ holidays }) {
  const [toDelete, setToDelete] = useState(null);
  async function onDeleteHoliday(id) {
    await deleteHoliday(id);
    setToDelete(null);
    getHolidays();
  }

  const data = (holidays || []).map((s) => {
    let dat = `${moment(s.start).local().format('DD MMMM')}`;
    if (!s.isRecurring) {
      dat = `${dat}, ${moment(s.start).local().format('Y')}`;
    }
    let time = `${moment(s.start).local().format('HH:mm')} - ${moment(s.end)
      .local()
      .format('HH:mm')}`;

    return {
      Namn: trimStr(s.name),
      'Återkommer årligen': s.isRecurring ? 'Ja' : 'Nej',
      Tidsspann: (
        <div>
          <p className={styles.datetime}>
            <CalendarIcon className={styles.icon} />
            {dat}
          </p>
          <p className={styles.datetime}>
            <ClockIcon className={styles.icon} />
            {time}
          </p>
        </div>
      ),
      ' ': (
        <div className={styles.mouseOver}>
          <Link className={styles.link} to={`/admin/holidays/${s.id}`}>
            <IconButton>
              <VisibilityIcon />
              <p className={styles.mouseOverText}>Titta & redigera</p>
            </IconButton>
          </Link>
          <IconButton className={styles.link} onClick={() => setToDelete(s)}>
            <DeleteOutline />
            <p className={styles.mouseOverText}>Ta bort</p>
          </IconButton>
        </div>
      ),
    };
  });
  return (
    <div className={styles.container}>
      {(!data || data.length === 0) && (
        <p className={styles.noEntryText}>
          Du har för närvarande inga inlagda helgdagar
        </p>
      )}
      {data.length > 0 && (
        <Table
          data={data}
          showSearch={true}
          searchPlaceholder="Sök efter helgdag"
          rowClass={styles.row}
        />
      )}
      {toDelete && (
        <ConfirmDialog
          open={toDelete !== null}
          title="Bekräfta borttagning"
          text={`Är du säker på att du vill ta bort '${toDelete.name}'?`}
          onDeny={() => setToDelete(null)}
          onConfirm={() => onDeleteHoliday(toDelete.id)}
        />
      )}
    </div>
  );
}

export default HolidayList;
