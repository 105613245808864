import React from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import TextInput from './TextInput';

const DEFAULT_REGION = 'SE';

export function isValidNbr(nbr) {
  return isValidPhoneNumber(nbr);
}

export function toI11(nbr) {
  return formatPhoneNumberIntl(nbr);
}

const PhoneField = (props) => (
  <PhoneInput
    defaultCountry={DEFAULT_REGION}
    inputComponent={TextInput}
    {...props}
  />
);

export default PhoneField;
