import holidayStore from '../stores/HolidayStore';
import { createSnack } from './SnackActions';
import { get, post, patch, del } from '../utils/api';
import { timeUtcToLocal, timeLocalToUtc } from '../utils/localization-utils';

export function clearHolidays() {
  holidayStore.holidays = [];
}

export function getHolidays(from, to) {
  let url = `/holidays`;
  if (from) url += `?from=${timeLocalToUtc(from).valueOf()}`;
  if (to) url += `&to=${timeLocalToUtc(to).valueOf()}`;
  get(url)
    .then((res) => {
      let { holidays } = res.data;
      holidays = (holidays || []).map((e) => ({
        ...e,
        start: timeUtcToLocal(e.start).valueOf(),
        end: timeUtcToLocal(e.end).valueOf(),
      }));
      holidayStore.holidays = holidays;
    })
    .catch(() => {
      createSnack('Kunde inte hämta helgdagar');
      holidayStore.holidays = [];
    });
}

export function getHoliday(id) {
  if (!id) return;
  return get(`/holidays/${id}`)
    .then((res) => {
      let { holiday } = res.data;
      holiday.start = timeUtcToLocal(holiday.start).valueOf();
      holiday.end = timeUtcToLocal(holiday.end).valueOf();

      holidayStore.holiday = holiday;
    })
    .catch(() => createSnack('Kunde inte hämta den helgdagen'));
}

export function addHoliday({
  name,
  start,
  end,
  relations,
  setAlarm,
  isRecurring,
}) {
  return post('/holidays', {
    name,
    start: timeLocalToUtc(start).valueOf(),
    end: timeLocalToUtc(end).valueOf(),
    relations,
    setAlarm,
    isRecurring,
  })
    .then(() => createSnack('Helgdag skapad'))
    .catch((e) => {
      createSnack(`Kunde inte skapa den helgdagen '${name}'`);
      throw e;
    });
}

export function updateHoliday({
  id,
  name,
  start,
  end,
  relations,
  setAlarm,
  isRecurring,
}) {
  return patch('/holidays', {
    id,
    name,
    start: timeLocalToUtc(start).valueOf(),
    end: timeLocalToUtc(end).valueOf(),
    relations,
    setAlarm,
    isRecurring,
  })
    .then(() => createSnack('Helgdag uppdaterad'))
    .catch((e) => {
      createSnack(`Kunde inte uppdatera den helgdagen '${name}'`);
      throw e;
    });
}

export function deleteHoliday(id) {
  return del(`/holidays/${id}`)
    .then(() => createSnack('Helgdag raderad'))
    .catch(() => createSnack('Kunde inte radera den helgdagen'));
}

export function clearHoliday() {
  holidayStore.holiday = null;
}
