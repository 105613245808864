import React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import TFASettings from './settings/TFASettings';
import ChangePass from '../components/settings/ChangePass';
import EditUser from '../components/settings/EditUser';
import styles from './Settings.module.css';

function Settings() {
  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.innerContainer}>
        <Sidebar
          className={styles.sidebar}
          title="Kontoinställningar"
          Icon={<SettingsIcon fontSize="large" />}
          text="Ändrar dina användaruppgifter och hantera tvåfaktorsautentisering"
        />

        <div className={styles.content}>
          <div className={styles.section}>
            <h3 className={styles.title}>Dina uppgifter</h3>
            <EditUser />
          </div>
          <div>
            <div className={styles.section}>
              <h3 className={styles.title}>Ditt lösenord</h3>
              <ChangePass />
            </div>
            <div className={styles.section}>
              <h3 className={styles.title}>Tvåfaktorsautentisering</h3>
              <TFASettings />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
