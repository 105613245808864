import createStore from '../utils/create-store';

export const PermissionGroupStore = createStore({
  permissionGroups: null,
  permissionGroup: null,
  permissions: null,
});

const permissionGroupStore = new PermissionGroupStore();

export default permissionGroupStore;
