import createStore from '../utils/create-store';

const CURRENT_SITE_ID = 'safe-current-store';

const _SiteStore = createStore({
  siteInfo: null,
  currentSite: parseInt(localStorage.getItem(CURRENT_SITE_ID), 10),
  sites: [],
  isFetching: false,
});

class SiteStore extends _SiteStore {
  setCurrentSite(siteId) {
    if (siteId !== null) {
      this.currentSite = siteId;
      localStorage.setItem(CURRENT_SITE_ID, siteId);
    }
  }

  unsetCurrentSite() {
    this.currentSite = 0;
    localStorage.removeItem(CURRENT_SITE_ID);
  }
}

export default new SiteStore();
