import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { SaveButton, UndoButton } from '../Buttons';

function ConfirmDialog({ open, title, text, onDeny, onConfirm }) {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
        <DialogActions>
          <UndoButton onClick={onDeny}>Ångra</UndoButton>
          <SaveButton onClick={onConfirm}>Bekräfta</SaveButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default ConfirmDialog;
