import React, { useEffect, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { Button, Divider, Modal, Popover } from '@material-ui/core';
import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';
import {
  Notifications as NotReadIcon,
  NotificationsNone as ReadIcon,
} from '@material-ui/icons';
import pushStore from '../stores/PushStore';
import {
  getPushNotifications,
  editPushNotification,
} from '../actions/PushActions';
import withState from '../utils/with-state';
import styles from './PushNotificationInbox.module.css';

const pollInt = 1000 * 60 * 30;

const NotifCard = ({ notif, onOpen = () => {} }) => {
  const onClick = async () => {
    editPushNotification(notif?.id, true).then(getPushNotifications);
    onOpen(notif);
  };

  const timestamp = notif?.createdAt
    ? moment(notif.createdAt).format('Y-MM-DD HH:mm:ss')
    : null;

  const Icon = () => (notif?.isRead ? <ReadIcon /> : <NotReadIcon />);
  return (
    <div className={styles.card} onClick={onClick}>
      <Icon />
      <p className={styles.cardSubject}>{notif?.subject}</p>
      <p className={styles.cardTimestamp} style={{ textAlign: 'right' }}>
        {timestamp ? `Skickad: ${timestamp}` : ''}
      </p>
    </div>
  );
};

const NotificationModal = ({ notif, setAsUnread, onClose }) => (
  <Modal
    open={Boolean(notif)}
    onClose={onClose}
    aria-labelledby="notification-title"
    aria-describedby="notification-desc"
  >
    <div className={styles.modal}>
      <div className={styles.modalSection}>
        <Button
          margin="dense"
          variant="outlined"
          onClick={setAsUnread}
          className={styles.unreadBtn}
        >
          Markera som oläst
        </Button>
      </div>
      <div className={classNames(styles.modalSection, styles.header)}>
        <h3 id="notification-title">{notif?.subject}</h3>
        <p>{moment(notif?.createdAt).format('Y-MM-DD HH:mm:ss')}</p>
      </div>
      <div className={styles.modalSection}>
        <p id="notification-desc">
          <ScopedCssBaseline
            style={{
              fontFamily: 'MaisonNeue, Arial, sans-serif',
              backgroundColor: 'white',
            }}
          >
            <ReactMarkdown remarkPlugins={[gfm]}>
              {notif?.message || ''}
            </ReactMarkdown>
          </ScopedCssBaseline>
        </p>
      </div>
    </div>
  </Modal>
);

function PushNotificationInbox({ anchor, closeInbox, notifications }) {
  const [open, setOpen] = useState(null);
  const handleClose = () => setOpen(null);
  useEffect(() => {
    getPushNotifications();
    let i = setInterval(() => {
      getPushNotifications();
    }, pollInt);
    return () => clearInterval(i);
  }, []);

  async function setUnread() {
    if (!open) return;
    await editPushNotification(open.id, false);
    getPushNotifications();
  }

  const nUnread = (notifications || []).filter((x) => !x.isRead).length;

  const title = `${nUnread} ny${nUnread !== 1 ? 'a' : ''} notifikation${
    nUnread !== 1 ? 'er' : ''
  }`;

  return (
    <Popover
      open={Boolean(anchor)}
      anchorEl={anchor}
      onClose={closeInbox}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <div className={styles.container}>
        <p className={styles.title}>{title}</p>
        <Divider />
        {(notifications || []).map((n) => (
          <NotifCard
            key={n?.id}
            notif={n}
            onOpen={setOpen}
            onClose={handleClose}
          />
        ))}
      </div>
      <NotificationModal
        notif={open}
        setAsUnread={setUnread}
        onClose={handleClose}
      />
    </Popover>
  );
}

export default withState(PushNotificationInbox, pushStore, (state) => ({
  notifications: state.notifications,
}));
