import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { IconButton, Tooltip } from '@material-ui/core';
import NotifIcon from '@material-ui/icons/Notifications';
import NewNotifIcon from '@material-ui/icons/NotificationsActive';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import PushNotificationInbox from './PushNotificationInbox';
import userStore from '../stores/UserStore';
import pushStore from '../stores/PushStore';
import withState from '../utils/with-state';
import { useHasPermissions, PERMISSIONS } from '../utils/permissions';
import styles from './Header.module.css';

import { ReactComponent as Logo } from '../images/logo_thin.svg';

const TfSpan = (t) => <span style={{ fontSize: '1rem' }}>{t}</span>;

const NotifsIcon = withState(
  ({ onClick, notifications }) => {
    const hasNew = (notifications || []).some((n) => !n?.isRead);
    return (
      <Tooltip title={TfSpan('Notiser')} placement="left">
        <IconButton size="small" onClick={onClick}>
          {hasNew ? (
            <NewNotifIcon className={styles.icon} />
          ) : (
            <NotifIcon className={styles.icon} />
          )}
        </IconButton>
      </Tooltip>
    );
  },
  pushStore,
  (state) => ({ notifications: state.notifications })
);

function Header() {
  const canViewRecordings = useHasPermissions(PERMISSIONS.RECORDINGS_VIEW);
  const canViewLiveStream = useHasPermissions(PERMISSIONS.LIVE_STREAM_VIEW);
  const canViewOverview = useHasPermissions(PERMISSIONS.DASHBOARD_READ);
  const canViewConfig = useHasPermissions(
    [
      PERMISSIONS.INSTRUCTIONS_READ,
      PERMISSIONS.SCHEDULES_READ,
      PERMISSIONS.EXCEPTIONS_READ,
      PERMISSIONS.AUTHORIZED_PEOPLE_READ,
      PERMISSIONS.POINT_OF_CONTACTS_READ,
      PERMISSIONS.TIMEOUT_CODES_READ,
    ],
    true
  );
  const [notifAnchor, setNotifAnchor] = useState(null);
  const toggleShowNotifs = (e) =>
    setNotifAnchor(notifAnchor ? null : e.currentTarget);
  const isAdmin = userStore.role === 'admin';

  const canViewUsers = useHasPermissions(PERMISSIONS.USERS_READ);

  return (
    <header className={styles.container}>
      <div className={styles.containerInner}>
        <NavLink className={styles.logo} to="/">
          <Logo alt="Safes logga" />
        </NavLink>
        <ul className={styles.menu}>
          <li>
            {canViewLiveStream && (
              <NavLink
                to="/tv"
                className={styles.link}
                activeClassName={styles.active}
              >
                TV
              </NavLink>
            )}
            {canViewOverview && (
              <NavLink
                to="/overview"
                className={styles.link}
                activeClassName={styles.active}
              >
                Översikt
              </NavLink>
            )}
            {(canViewRecordings || canViewLiveStream) && (
              <NavLink
                to="/cameras"
                className={styles.link}
                activeClassName={styles.active}
              >
                Kameror
              </NavLink>
            )}
            {canViewConfig && (
              <NavLink
                to="/configuration"
                className={styles.link}
                activeClassName={styles.active}
              >
                Konfiguration
              </NavLink>
            )}
            {(isAdmin || canViewUsers) && (
              <NavLink
                to="/admin"
                className={styles.link}
                activeClassName={styles.active}
              >
                Admin
              </NavLink>
            )}
          </li>
        </ul>
        <div className={styles.buttons}>
          <NotifsIcon onClick={toggleShowNotifs} />
          <Tooltip title={TfSpan('Inställningar')}>
            <NavLink
              to="/settings"
              className={styles.link}
              activeClassName={styles.active}
            >
              <IconButton size="small">
                <SettingsIcon className={styles.icon} />
              </IconButton>
            </NavLink>
          </Tooltip>
          <Tooltip title={TfSpan('Logga ut')}>
            <IconButton size="small" href="/logout">
              <ExitToAppIcon className={styles.icon} />
            </IconButton>
          </Tooltip>
          <PushNotificationInbox
            anchor={notifAnchor}
            closeInbox={toggleShowNotifs}
          />
        </div>
      </div>
    </header>
  );
}

export default Header;
