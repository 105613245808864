export const ALARM_DESCRIPTIONS = {
  motion: 'Rörelse',
  analysis: 'Rörelse (analys)',
  analysis_error: 'Analysfel',
  internal_error: 'Internt fel',
  tampering: 'Kameramanipulering',
  camera_alarm: 'Kameralarm',
  lost_camera_connection: 'Anslutningsfel',
  other: 'Övrigt',
  high_latency: 'Långsam anslutning',
  no_video: 'Ingen video',
};

export const GROUP_MAP = {
  instructions: 'Instruktioner',
  'live stream': 'Liveströmmar',
  'authorized people': 'Behöriga personer',
  exceptions: 'Undantag',
  schedules: 'Scheman',
  'point of contacts': 'Kontaktpersoner',
  'timeout codes': 'Temporära koder',
  alarms: 'Larm',
  recordings: 'Inspelningar',
  'motion detections': 'Rörelsedetektioner',
  dashboard: 'Översikt',
  'audit logs': 'Loggar',
  'timeout logs': 'Avlarmningsloggar',
  users: 'Användare',
  documents: 'Dokument',
};

export const VERB_MAP = {
  create: 'Skapa',
  read: 'Läs',
  update: 'Uppdatera',
  delete: 'Ta bort',
  view: 'Visa',
  control: 'Kontrollera',
  approve: 'Markera som läst',
  osm: 'OSM',
};
