import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  IconButton,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { Delete } from '@material-ui/icons';
import { SaveButton } from '../Buttons';
import Loading from '../Loading';
import PhoneField from '../PhoneField';
import MultiSelect from '../MultiSelect';
import TextInput from '../TextInput';
import ConfirmDialog from '../configuration/ConfirmDialog';
import { getPermissionGroups } from '../../actions/PermissionGroupActions';
import permissionGroupStore from '../../stores/PermissionGroupStore';
import siteStore from '../../stores/SiteStore';
import userStore from '../../stores/UserStore';
import { withStates } from '../../utils/with-state';
import PermissionGroupDialog from './PermissionGroupDialog';
import { useHasPermissions, PERMISSIONS } from '../../utils/permissions';
import {
  clearUser,
  deleteUser as deleteUserAction,
} from '../../actions/UserActions';
import styles from './EditUser.module.css';

function EditUser({
  user,
  sites,
  permissionGroups,
  onSave,
  currentSite,
  userRole,
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [phoneNbr, setPhoneNbr] = useState('');
  const [permissionGroupId, setPermissionGroupId] = useState(null);
  const [selectedSites, setSites] = useState([]);
  const [toDelete, setToDelete] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const canEdit = useHasPermissions(PERMISSIONS.USERS_UPDATE);
  const canDelete = useHasPermissions(PERMISSIONS.USERS_DELETE);

  useEffect(() => {
    getPermissionGroups();

    return () => clearUser();
  }, []);

  useEffect(() => {
    if (user && sites) {
      setFname(user.firstName);
      setLname(user.lastName);
      setPhoneNbr(user?.phoneNbr);
      setPermissionGroupId(user?.permissionGroup?.id || null);
      setSites(
        (user?.sites || []).reduce(
          (acc, x) => [...acc, sites.find((s) => s.id === x)],
          []
        )
      );
    }
  }, [user, sites]);

  function onSubmit(e) {
    e.preventDefault();
    onSave({
      firstName: fname,
      lastName: lname,
      phoneNbr,
      permissionGroupId,
      selectedSites: currentSite ? null : selectedSites,
      ignoreSites: !!currentSite,
    });
  }

  function deleteUser() {
    if (currentSite) {
      deleteUserAction(user.id, { siteId: currentSite }).then(() => {
        setRedirect(true);
      });
    } else {
      deleteUserAction(user.id).then(() => {
        setRedirect(true);
      });
    }
  }

  if (!user || !permissionGroups) return <Loading center big />;

  if (redirect) return <Redirect to="/admin/users" />;

  let allPermissionGroups = structuredClone(permissionGroups);

  const adminRestrictedGroupEdit =
    !permissionGroups.find((x) => x.id === user?.permissionGroup?.id) &&
    !!user?.permissionGroup;

  if (adminRestrictedGroupEdit && user.permissionGroup) {
    allPermissionGroups.push(user.permissionGroup);
  }

  return (
    <form onSubmit={onSubmit} className={styles.form}>
      <div className={styles.row}>
        <TextInput
          className={styles.entry}
          label="Förnamn"
          value={fname}
          onChange={(e) => setFname(e.target.value)}
        />
        <TextInput
          className={styles.entry}
          label="Efternamn"
          value={lname}
          onChange={(e) => setLname(e.target.value)}
        />
      </div>
      <TextInput
        className={styles.entry}
        label="Email"
        value={user?.email}
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
        disabled
      />
      <div className={styles.row}>
        {userRole === 'admin' && (
          <TextInput
            className={styles.entry}
            label="Roll"
            value={user?.role}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            disabled
          />
        )}
        {user?.role !== 'Admin' && (
          <>
            <FormControl
              variant="outlined"
              className={styles.entry}
              margin="dense"
            >
              <InputLabel>Behörighetsgrupp</InputLabel>
              <Select
                label="Behörighetsgrupp"
                value={user?.permissionGroup?.id || permissionGroupId || ''}
                onChange={(e) => setPermissionGroupId(e.target.value)}
                margin="dense"
                disabled={adminRestrictedGroupEdit}
              >
                {allPermissionGroups.map((permissionGroup) => (
                  <MenuItem key={permissionGroup.id} value={permissionGroup.id}>
                    {permissionGroup.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setDialogOpen(true);
              }}
              disabled={permissionGroupId === null}
              className={styles.infoButton}
              size="small"
            >
              <InfoIcon />
            </IconButton>
            <PermissionGroupDialog
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
              permissionGroup={user?.permissionGroup}
            />
          </>
        )}
      </div>
      <div>
        <PhoneField
          label="Telefonnummer"
          value={phoneNbr}
          onChange={(e) => setPhoneNbr(e)}
        />
      </div>
      {!currentSite && (
        <MultiSelect
          className={styles.entry}
          label="Länkade anläggningar"
          list={sites}
          selectedValues={selectedSites}
          selectValues={setSites}
          renderValue={(s) =>
            `${s.length} Anläggning${s.length > 1 ? 'ar' : ''}`
          }
          searchable
          disabled={(user?.role || '').toLowerCase() === 'admin'}
        />
      )}
      <div className={styles.footer}>
        {canDelete &&
          (currentSite ? (
            <SaveButton
              admin
              onClick={() => setToDelete(true)}
              startIcon={<Delete />}
              disabled={user?.id === 1}
            >
              Koppla bort användare
            </SaveButton>
          ) : (
            <SaveButton
              admin
              onClick={() => setToDelete(true)}
              startIcon={<Delete />}
              disabled={user?.id === 1}
            >
              Radera användare
            </SaveButton>
          ))}
        {canEdit && <SaveButton type="submit">Spara ändringar</SaveButton>}
      </div>
      <ConfirmDialog
        open={toDelete}
        title={currentSite ? 'Koppla bort användare' : 'Radera användare'}
        text={
          <>
            <p>
              Är du säker på att du vill{' '}
              {currentSite ? 'koppla bort' : 'radera'} {fname} {lname}?
            </p>
            <p>
              {currentSite ? (
                <span>
                  Användaren kommer inte längre ha tillgång till anläggningen.
                </span>
              ) : (
                <span>
                  Detta kommer ta bort{' '}
                  <span style={{ color: 'red', fontWeight: '500' }}>ALL</span>{' '}
                  data relaterad till den!
                </span>
              )}
            </p>
          </>
        }
        onDeny={() => setToDelete(false)}
        onConfirm={deleteUser}
      />
    </form>
  );
}

const permissionGroupMapper = ({ permissionGroups }) => ({
  permissionGroups,
});

const userMapper = ({ role }) => ({
  userRole: role,
});

const siteMapper = ({ currentSite }) => ({
  currentSite,
});

export default withStates(
  EditUser,
  [permissionGroupStore, userStore, siteStore],
  [permissionGroupMapper, userMapper, siteMapper]
);
