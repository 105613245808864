import alarmTagsStore from '../stores/AlarmTagsStore';
import { get, put, post } from '../utils/api';
import { createSnack } from './SnackActions';

export async function getAlarmTags() {
  try {
    const res = await get('/alarm-tags');
    alarmTagsStore.alarmTags = res?.data?.tags;
  } catch (err) {
    console.error(err); // eslint-disable-line no-console
    createSnack('Kunde inte hämta larm taggar');
  }
}

export function createAlarmTag({ name }) {
  return post('/alarm-tags', { name, color: '' })
    .then(() => createSnack('Larmtagg skapades'))
    .catch(() => createSnack('Kunde inte skapa larmtagg'));
}
export function updateAlarmTag(id, { name, archived }) {
  return put(`/alarm-tags/${id}`, { name, archived })
    .then(() => createSnack('Larmtagg uppdaterades'))
    .catch(() => createSnack('Kunde inte uppdatera larmtagg'));
}
