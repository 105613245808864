import React from 'react';
import classNames from 'classnames';
import styles from './Loading.module.css';

function Loading({ label, big, center, style = {} }) {
  return (
    <div
      style={{ marginTop: center ? 200 : 0, ...style }}
      className={styles.container}
    >
      <div className={styles.innerContainer}>
        <div className={classNames(styles.loading, { [styles.big]: big })}>
          <span className={styles.red} />
          <span className={styles.orange} />
          <span className={styles.green} />
        </div>
        {Boolean(label) && <p style={{ paddingTop: '1rem' }}>{label}</p>}
      </div>
    </div>
  );
}

export default Loading;
