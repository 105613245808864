import React, { useState } from 'react';
import { InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { SaveButton } from '../Buttons';
import TextInput from '../TextInput';
import { changePassword } from '../../actions/UserActions';
import styles from './ChangePass.module.css';

function PassField({ label, val, setVal }) {
  const [showPass, setShowPass] = useState(false);
  return (
    <TextInput
      className={styles.row}
      label={label}
      type={showPass ? 'text' : 'password'}
      value={val}
      onChange={(e) => setVal(e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="Visa lösenord"
              onClick={() => setShowPass(!showPass)}
              onMouseDown={(e) => e.preventDefault()}
              size="small"
            >
              {showPass ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

function ChangePass() {
  const [error, setError] = useState(null);
  const [oldPass, setOldPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');

  function onPassChange(e) {
    e.preventDefault();
    if (oldPass && newPass && confirmPass && newPass === confirmPass) {
      changePassword({ oldPass, newPass }).catch(setError);
    } else if (!newPass || !confirmPass) {
      setError(
        new Error(
          'Du behöver fylla i alla fälten innan du kan skicka det nya lösenordet'
        )
      );
    } else if (newPass !== confirmPass) {
      setError(
        new Error('Det nya lösenordet och bekräftelsen måste vara lika')
      );
    }
  }

  return (
    <div className={styles.container}>
      {error && <p>{error.message}</p>}
      <form onSubmit={onPassChange} className={styles.form}>
        <PassField label="Gammalt lösenord" val={oldPass} setVal={setOldPass} />
        <PassField label="Nytt lösenord" val={newPass} setVal={setNewPass} />
        <PassField
          label="Bekräfta nytt lösenord"
          val={confirmPass}
          setVal={setConfirmPass}
        />
        <div className={styles.footer}>
          <SaveButton type="submit">Ändra lösenord</SaveButton>
        </div>
      </form>
    </div>
  );
}

export default ChangePass;
