import permissionGroupStore from '../stores/PermissionGroupStore';
import { createSnack } from './SnackActions';
import { get, post, put, del } from '../utils/api';

export function clearPermissionGroups() {
  permissionGroupStore.permissionGroups = [];
}

export function getPermissionGroups() {
  return get('/permission-groups')
    .then((res) => {
      permissionGroupStore.permissionGroups = res.data;
    })
    .catch(() => {
      createSnack('Kunde inte hämta behörighetsgrupper');
      permissionGroupStore.permissionGroups = [];
    });
}

export function getPermissionGroup(id) {
  if (!id) return;

  return get(`/permission-groups/${id}`)
    .then((res) => {
      permissionGroupStore.permissionGroup = res.data;
    })
    .catch(() => createSnack('Kunde inte hämta den behörighetsgruppen'));
}

export function addPermissionGroup({ name, permissions, isAdminExclusive }) {
  return post('/permission-groups', {
    name,
    permissions,
    isAdminExclusive,
  })
    .then(() => createSnack('Behörighetsgrupp skapad'))
    .catch((e) => {
      createSnack(`Kunde inte skapa behörighetsgruppen '${name}'`);
      throw e;
    });
}

export function updatePermissionGroup({
  id,
  name,
  permissions,
  isAdminExclusive,
}) {
  return put(`/permission-groups/${id}`, {
    id,
    name,
    permissions,
    isAdminExclusive,
  })
    .then(() => createSnack('Behörighetsgrupp uppdaterad'))
    .catch((e) => {
      createSnack(`Kunde inte uppdatera behörighetsgruppen '${name}'`);
      throw e;
    });
}

export function deletePermissionGroup(id) {
  return del(`/permission-groups/${id}`)
    .then(() => createSnack('Behörighetsgrupp raderad'))
    .catch(() =>
      createSnack(
        'Kunde inte radera den behörighetsgruppen. Antagligen används den.'
      )
    );
}

export function clearPermissionGroup() {
  permissionGroupStore.permissionGroup = null;
}

export function getPermissions() {
  if (permissionGroupStore.permissions) return Promise.resolve();

  return get('/permissions')
    .then((res) => {
      permissionGroupStore.permissions = res.data.permissions;
    })
    .catch(() => {
      createSnack('Kunde inte hämta behörigheter');
      permissionGroupStore.permissions = null;
    });
}
