import React, { useEffect } from 'react';
import { IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import LabelIcon from '@material-ui/icons/Label';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import { SaveButton, AddButton } from '../../Buttons';
import alarmTagsStore from '../../../stores/AlarmTagsStore';
import { getAlarmTags } from '../../../actions/AlarmTagsActions';
import { trimStr } from '../../../utils/generation';
import Table from '../../Table';
import withState from '../../../utils/with-state';
import EditAlarmTag from './EditAlarmTag';
import AddAlarmTag from './AddAlarmTag';
import Sidebar from '../../Sidebar';
import aStyles from './AlarmTags.module.css';
import moStyles from '../../mouseOver.module.css';

const styles = { ...aStyles, ...moStyles };

function EmptyContainer() {
  return (
    <>
      <div className={styles.header}>
        <h3>Alla larmtaggar</h3>
      </div>
      <div className={styles.empty}>
        <h2 className={styles.emptyTitle}>
          Du har för närvarande inga inlagda larmtaggar
        </h2>
        <div className={styles.iconCircle}>
          <LabelIcon className={styles.emptyIcon} />
        </div>
        <div>
          <SaveButton href="/admin/alarm-tags/new">Skapa larmtagg</SaveButton>
        </div>
      </div>
    </>
  );
}

function AlarmTagsList({ alarmTags }) {
  const data = (alarmTags || []).map((tag) => ({
    Namn: trimStr(tag.name),
    Arkiverad: tag.archived ? 'Ja' : 'Nej',
    ' ': (
      <div className={styles.mouseOver}>
        <Link className={styles.link} to={`/admin/alarm-tags/${tag.id}`}>
          <IconButton>
            <VisibilityIcon />
            <p className={styles.mouseOverText}>Titta & redigera</p>
          </IconButton>
        </Link>
      </div>
    ),
  }));

  return (
    <div className={styles.container}>
      <div className={styles.containerInner}>
        <Sidebar
          title="Larmtaggar"
          text="Hantera dina larmtaggar här"
          Icon={<LabelIcon fontSize="large" />}
        />
        <div className={styles.content}>
          {!alarmTags?.length ? <EmptyContainer /> : null}
          {alarmTags?.length ? (
            <>
              <div className={styles.header}>
                <h3>Alla larmtaggar</h3>
                <AddButton href="/admin/alarm-tags/new">Skapa ny</AddButton>
              </div>
              <Table
                data={data}
                showSearch={true}
                searchPlaceholder="Sök efter taggar"
                rowClass={styles.row}
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}

function AlarmTags({ alarmTags }) {
  const match = useRouteMatch();
  useEffect(() => {
    getAlarmTags();
  }, []);

  return (
    <Switch>
      <Route exact path={match.path}>
        <AlarmTagsList alarmTags={alarmTags} />
      </Route>
      <Route path={`${match.path}/new`} component={AddAlarmTag} />
      <Route
        path={`${match.path}/:id`}
        render={(props) => {
          const { id } = props?.match?.params || {};
          const alarmTag = (alarmTags || []).find(
            (x) => x.id === parseInt(id, 10)
          );
          return <EditAlarmTag alarmTag={alarmTag} />;
        }}
      />
    </Switch>
  );
}

export default withState(AlarmTags, alarmTagsStore, ({ alarmTags }) => ({
  alarmTags,
}));
