import { createSnack } from './SnackActions';
import pushStore from '../stores/PushStore';
import { get, post, put } from '../utils/api';

export function getPushNotifications(params = { limit: 10 }) {
  let query = ['fromMs', 'limit', 'offset']
    .filter((x) => params[x])
    .map((x) => `${x}=${params[x]}`)
    .join('&');
  return get(`/push-notifs${query ? `?${query}` : ''}`).then((res) => {
    const { notifications } = res.data;
    pushStore.notifications = notifications;
  });
}

export function sendPushNotification(
  userIds,
  permissionGroups,
  subject,
  message
) {
  return post('/push-notifs', { userIds, permissionGroups, subject, message })
    .then(() => createSnack(`Pushnotis skickad`))
    .catch(() => createSnack('Kunde inte skicka notifikationen'));
}

export function editPushNotification(id, isRead) {
  return put('/push-notifs', { id, isRead }).catch(() =>
    createSnack('Kunde inte justera notisens status')
  );
}
