import React from 'react';
import ReactDOM from 'react-dom';
import 'moment/locale/sv';
import {
  StylesProvider,
  createTheme,
  ThemeProvider,
} from '@material-ui/core/styles';
import { svSE } from '@material-ui/core/locale';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { renewToken } from './actions/UserActions';
import userStore from './stores/UserStore';
import { hasTfa } from './actions/TfaActions';

const THEME = createTheme(
  {
    typography: {
      fontFamily: 'MaisonNeue',
    },
    transitions: {
      create: () => 'none',
    },
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
  },
  svSE
);

function renderApp() {
  ReactDOM.render(
    <React.StrictMode>
      <StylesProvider injectFirst>
        <ThemeProvider theme={THEME}>
          <App />
        </ThemeProvider>
      </StylesProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

if (userStore.token !== null) {
  renewToken().then(hasTfa).finally(renderApp);
} else {
  renderApp();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
