import { get } from '../utils/api';
import userListStore from '../stores/UserListStore';

/* eslint-disable-next-line import/prefer-default-export */
export function fetchUsers(siteId = 0) {
  const roles = {
    customer: 'Kund',
    admin: 'Admin',
  };

  return get(`/sites/${siteId}/users`).then((res) => {
    const users = (res.data.users || []).map((user) => ({
      ...user,
      role: user.isEmployee ? 'Anställd' : roles[user.role],
    }));
    userListStore.users = users;
    return { users, siteId };
  });
}

export function clearUsers() {
  userListStore.users = null;
}
