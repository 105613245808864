import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import EditUser from './EditUser';
import { toI11 as formatNbr } from '../PhoneField';
import Loading from '../Loading';
import Sidebar from '../Sidebar';
import { getUser, editUser, clearUser } from '../../actions/UserActions';
import { fetchUsers } from '../../actions/UserListActions';
import { fetchSites } from '../../actions/SiteActions';
import siteStore from '../../stores/SiteStore';
import userStore from '../../stores/UserStore';
import withState from '../../utils/with-state';
import styles from './User.module.css';

function User({ userInfo, match, sites, currentSite }) {
  const userId = match?.params?.id;
  const [redirect, setRedirect] = useState(false);
  useEffect(
    () => () => {
      clearUser();
    },
    []
  );
  useEffect(() => {
    clearUser();
    fetchSites(false);
    if (userId) getUser(userId);
  }, [userId]);

  function onSave({
    firstName,
    lastName,
    phoneNbr,
    selectedSites,
    permissionGroupId,
    ignoreSites,
  }) {
    editUser({
      id: userInfo.id,
      firstName,
      lastName,
      phoneNbr: formatNbr(phoneNbr),
      sites: (selectedSites || []).map((x) => x.id),
      permissionGroupId,
      ignoreSites,
    }).then(() => {
      clearUser();
      fetchUsers(currentSite);
      setRedirect(true);
    });
  }

  if (redirect) return <Redirect to="/admin/users" />;

  return (
    <div className={styles.container}>
      <div className={styles.containerInner}>
        <Sidebar title="Uppdatera användare" backRoute="/admin/users" />
        <div className={styles.content}>
          {!userInfo ? (
            <Loading big center />
          ) : (
            <div className={styles.formContainer}>
              {userInfo && sites && (
                <EditUser user={userInfo} sites={sites} onSave={onSave} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default withState(
  withState(User, userStore, (state) => ({
    userInfo: state.userInfo,
  })),
  siteStore,
  (state) => ({ sites: state.sites, currentSite: state.currentSite })
);
