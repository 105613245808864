import authStore from '../stores/TfaStore';
import { get, post, del } from '../utils/api';
import { createSnack } from './SnackActions';

export async function hasTfa() {
  return get('/has-tfa').then((res) => {
    authStore.usesTfa$.next(res.data.hasTfa);
  });
}

export async function setupTfa() {
  return get('/two-factor-auth')
    .then((res) => {
      // authStore.verified$.next(res.data.otpUrl === '');
      authStore.otpUrl = res.data.otpUrl;
      authStore.manCode = res.data?.manCode;
    })
    .catch(() =>
      createSnack('Upprättning av tvåfaktorsautentisering misslyckades')
    );
}

export async function authTfa(otpToken) {
  if (otpToken === '') return;
  return post('/two-factor-auth', { otpToken })
    .then(() => {
      authStore.usesTfa$.next(true);
      authStore.verified$.next(true);
    })
    .catch(() => {
      authStore.usesTfa$.next(false);
      authStore.verified$.next(false);
      createSnack('Verifiering misslyckades');
    });
}

export async function deleteTfa(otpToken) {
  return del('/two-factor-auth', { otpToken }).then(() => {
    authStore.usesTfa$.next(false);
    authStore.verified$.next(false);
    authStore.otpUrl$.next('');
    createSnack('Tvåfaktorsautentisering borttagen');
  });
}
