import React from 'react';
import classNames from 'classnames';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';

import styles from './SearchBar.module.css';

function SearchBar({ placeholder, list, ...props }) {
  return (
    <div className={styles.container}>
      <TextField
        className={styles.field}
        id="site-list-search-field"
        placeholder={placeholder}
        options={list}
        variant="outlined"
        margin="dense"
        InputProps={{
          startAdornment: <SearchIcon className={styles.icon} />,
          endAdornment: (
            <ClearIcon
              className={classNames(styles.icon, styles.clearBtn, {
                [styles.hideBtn]: props?.value === '',
              })}
              onClick={() => props?.onInput({ target: { value: '' } })}
            />
          ),
          ...{ ...(props?.InputProps || {}) },
        }}
        {...props}
      />
    </div>
  );
}

export default SearchBar;
