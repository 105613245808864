import React, { useState } from 'react';
import { TextField, Button } from '@material-ui/core';

function VerifyTfa({ onVerify }) {
  const [verifyCode, setVerifyCode] = useState('');
  return (
    <>
      <TextField
        label="Verifieringskod"
        value={verifyCode}
        onChange={(e) => setVerifyCode(e.target.value)}
      />
      <Button
        variant="contained"
        onClick={() => onVerify(verifyCode.replace(/[^0-9]/g, ''))}
      >
        Verifiera
      </Button>
    </>
  );
}

export default VerifyTfa;
