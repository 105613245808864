import React from 'react';
import { TextField } from '@material-ui/core';

const TextInput = React.forwardRef((props, ref) => (
  <TextField
    inputRef={ref}
    className="form-entry"
    variant="outlined"
    margin="dense"
    {...props}
  />
));

export default TextInput;
