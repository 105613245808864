import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { getProfile } from '../actions/UserActions';

export const PERMISSIONS = {
  INSTRUCTIONS_CREATE: 'instructionsCreate',
  INSTRUCTIONS_READ: 'instructionsRead',
  INSTRUCTIONS_UPDATE: 'instructionsUpdate',
  INSTRUCTIONS_DELETE: 'instructionsDelete',
  LIVE_STREAM_VIEW: 'liveStreamView',
  LIVE_STREAM_CONTROL: 'liveStreamControl',
  LIVE_STREAM_OSM: 'liveStreamOsm',
  AUTHORIZED_PEOPLE_CREATE: 'authorizedPeopleCreate',
  AUTHORIZED_PEOPLE_READ: 'authorizedPeopleRead',
  AUTHORIZED_PEOPLE_UPDATE: 'authorizedPeopleUpdate',
  AUTHORIZED_PEOPLE_DELETE: 'authorizedPeopleDelete',
  EXCEPTIONS_CREATE: 'exceptionsCreate',
  EXCEPTIONS_READ: 'exceptionsRead',
  EXCEPTIONS_UPDATE: 'exceptionsUpdate',
  EXCEPTIONS_DELETE: 'exceptionsDelete',
  SCHEDULES_CREATE: 'schedulesCreate',
  SCHEDULES_READ: 'schedulesRead',
  SCHEDULES_UPDATE: 'schedulesUpdate',
  SCHEDULES_DELETE: 'schedulesDelete',
  POINT_OF_CONTACTS_CREATE: 'pointOfContactsCreate',
  POINT_OF_CONTACTS_READ: 'pointOfContactsRead',
  POINT_OF_CONTACTS_UPDATE: 'pointOfContactsUpdate',
  POINT_OF_CONTACTS_DELETE: 'pointOfContactsDelete',
  TIMEOUT_CODES_CREATE: 'timeoutCodesCreate',
  TIMEOUT_CODES_READ: 'timeoutCodesRead',
  TIMEOUT_CODES_DELETE: 'timeoutCodesDelete',
  ALARMS_READ: 'alarmsRead',
  RECORDINGS_VIEW: 'recordingsView',
  MOTION_DETECTIONS_READ: 'motionDetectionsRead',
  DASHBOARD_READ: 'dashboardRead',
  AUDIT_LOGS_READ: 'auditLogsRead',
  TIMEOUT_LOGS_READ: 'timeoutLogsRead',
  USERS_CREATE: 'usersCreate',
  USERS_READ: 'usersRead',
  USERS_UPDATE: 'usersUpdate',
  USERS_DELETE: 'usersDelete',
  DOCUMENTS_READ: 'documentsRead',
  DOCUMENTS_APPROVE: 'documentsApprove',
};

export const useHasPermissions = (permissions, any = false) => {
  const { data: user } = useQuery('user', getProfile, {
    staleTime: 30000,
    cacheTime: 60000,
  });

  const hasPermissions = useMemo(() => {
    const permissionsArray =
      typeof permissions === 'string' ? [permissions] : permissions;

    if (
      !permissionsArray.every((permission) => typeof permission === 'string')
    ) {
      throw new Error('Permissions must be a string or an array of strings');
    }

    if (!user) return false;
    if (user.role === 'admin') return true;
    if (!user.permissionGroup?.permissions) return false;

    return any
      ? permissionsArray.some(
          (permission) => user.permissionGroup.permissions[permission]
        )
      : permissionsArray.every(
          (permission) => user.permissionGroup.permissions[permission]
        );
  }, [user, permissions, any]);

  return hasPermissions;
};
