import React from 'react';
import { Redirect } from 'react-router-dom';
import userStore from '../stores/UserStore';
import withState from '../utils/with-state';

function LoggedInWrapper({ Component, admin = false, token, ...props }) {
  if (!token) {
    return <Redirect to="/login" />;
  }

  if (admin && userStore.role !== 'admin') {
    return <Redirect to="/" />;
  }

  return <Component {...props} />;
}

export default withState(LoggedInWrapper, userStore, (state) => ({
  token: state.token,
}));
