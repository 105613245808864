import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff, MailOutline } from '@material-ui/icons';
import { SaveButton } from './Buttons';
import TextInput from './TextInput';
import { login } from '../actions/UserActions';
import tfaStore from '../stores/TfaStore';
import userStore from '../stores/UserStore';
import { withStates } from '../utils/with-state';
import styles from './Login.module.css';

import { ReactComponent as Logo } from '../images/wings.svg';
import { ReactComponent as EyeIllustration } from '../images/safe_eye_dark.svg';

function Login({ usesTfa, token, verified }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [firstPart, setFirstPart] = useState(true);
  const [tfaCode, setTfaCode] = useState('');

  function doLogin(e) {
    e.preventDefault();
    login(email, password, tfaCode.replace(/\D/g, ''))
      .then(({ usesTfa: _usesTfa }) => {
        if (_usesTfa && !verified) setFirstPart(!firstPart);
      })
      .catch((err) => {
        let errObj = err?.response?.data?.error;
        setError(errObj);
      });
  }

  if (token) {
    return <Redirect to="/" />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Logo className={styles.logo} title="Safes logga" />
        <h1>Logga in på Portalen</h1>
        {error && <p className={styles.error}>{error.details}</p>}
        <form onSubmit={doLogin} className={styles.form}>
          {firstPart && (
            <>
              <div className={styles.formElement}>
                <p>E-mail</p>
                <TextInput
                  placeholder="namn@email.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  InputProps={{
                    className: styles.formField,
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailOutline />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className={styles.formElement}>
                <p>Lösenord</p>
                <TextInput
                  placeholder={'**************'}
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    className: styles.formField,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Visa lösenord"
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={(e) => e.preventDefault()}
                          size="small"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </>
          )}
          {!firstPart && usesTfa && !verified && !token && (
            <div className={styles.tfaBox}>
              <p>Skriv in tvåfaktorskoden från din app</p>
              <TextInput
                label="Verifieringskod"
                value={tfaCode}
                onChange={(e) => setTfaCode(e.target.value)}
                InputProps={{
                  className: styles.formField,
                }}
              />
            </div>
          )}
          <div>
            <SaveButton
              type="submit"
              disabled={email === '' || password === ''}
            >
              Logga in
            </SaveButton>
          </div>
        </form>
        <div className={styles.links}>
          <Link to="/forgot-password">Glömt Lösenord?</Link>
          <Link to="/privacy-policy">Integritetspolicy</Link>
        </div>
        <EyeIllustration className={styles.bigEye} title="Öga illustration" />
      </div>
      <div className={styles.illustration}>
        <div className={styles.slogan}>
          <h1>Din Digitala Väktare</h1>
          <h3>Se, styr och organisera</h3>
        </div>
      </div>
    </div>
  );
}

const userMapper = (state) => ({ token: state.token });
const tfaMapper = (state) => ({
  usesTfa: state.usesTfa,
  verified: state.verified,
});

export default withStates(
  Login,
  [userStore, tfaStore],
  [userMapper, tfaMapper]
);
