import React, { useState, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import DescriptionIcon from '@material-ui/icons/Description';
import Document from './Document';
import Sidebar from '../../Sidebar';
import { UndoButton } from '../../Buttons';
import documentStore from '../../../stores/DocumentStore';
import {
  updateDocument,
  getDocument,
  getDocuments,
  clearDocument,
} from '../../../actions/DocumentActions';
import { withStates } from '../../../utils/with-state';
import siteStore from '../../../stores/SiteStore';

import styles from '../../configuration/EditForm.module.css';
import { fetchSites } from '../../../actions/SiteActions';
import Loading from '../../Loading';

function EditDocument({ doc, sites, match }) {
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState(null);

  const clearError = () => setError(null);
  const location = useLocation();

  useEffect(() => {
    const id = match?.params?.id;
    fetchSites(false);
    if (id) getDocument(id, location?.siteId);
  }, [match, location]);

  function onSaveDocument({ name, file, siteId }) {
    if (doc && doc.id) {
      updateDocument({
        id: doc.id,
        name,
        file,
        siteId,
      })
        .then(() => {
          clearDocument();
          getDocuments();
          setRedirect(true);
        })
        .catch((e) => setError(e));
    }
  }

  if (redirect) return <Redirect to="/admin/documents" />;

  if (!doc) return <Loading big center />;

  return (
    <div className={styles.container}>
      <div className={styles.containerInner}>
        <Sidebar
          title="Justera ditt dokument"
          text="Uppdatera ditt valda dokument här"
          Icon={<DescriptionIcon fontSize="large" />}
        />
        {doc && (
          <div className={styles.content}>
            <div className={styles.header}>
              <h2>Dokumenthanterare</h2>
              <UndoButton href="/admin/documents">Ångra & stäng</UndoButton>
            </div>
            <div className={styles.form}>
              {error && (
                <p className={styles.error}>
                  {error?.response?.data?.error?.details}
                </p>
              )}
              <Document
                doc={doc}
                sites={sites}
                onSubmit={onSaveDocument}
                clearError={clearError}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function siteMapper({ sites }) {
  return { sites };
}

export default withStates(
  EditDocument,
  [documentStore, siteStore],
  [
    (state) => ({
      doc: state.doc,
    }),
    siteMapper,
  ]
);
