import createStore from '../utils/create-store';

const PTZ_SPEED_ID = 'ptz-speed';

const _CameraStore = createStore({
  configuration: null,
  settings: null,
  isFetchingConfig: false,
  ptzSpeed: parseFloat(localStorage.getItem(PTZ_SPEED_ID), 10) || 0.6,
});

export class CameraStore extends _CameraStore {
  setPtzSpeed(s) {
    this.ptzSpeed = s;
    localStorage.setItem(PTZ_SPEED_ID, s);
  }
}

export default new CameraStore();
