import React, { useState, useEffect } from 'react';
import {
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
} from '@material-ui/core';
import { SaveButton } from '../../Buttons';
import Table from '../../Table';

import TextInput from '../../TextInput';
import hStyles from './Document.module.css';
import fStyles from '../../configuration/Form.module.css';

const styles = { ...fStyles, ...hStyles };

function Document({ doc, sites, onSubmit, clearError }) {
  const [name, setName] = useState(doc?.name || '');
  const [site, setSite] = useState(doc?.siteId);
  const [err, setErr] = useState({
    name: {
      err: false,
      text: 'Måste vara mellan 1 och 255 tecken',
    },
    site: {
      err: false,
      text: 'Måste välja anläggning',
    },
  });

  useEffect(() => {
    setName(doc?.name || '');
    setSite(doc?.siteId || 0);
  }, [doc]);

  function checkError() {
    for (let e of Object.keys(err)) {
      let eVal;
      switch (e) {
        case 'name':
          eVal = name.length > 255 || name.length < 1;
          break;
        case 'site':
          eVal = !site;
          break;
        default:
          break;
      }
      if (eVal !== null) {
        let newErr = { ...err };
        newErr[e].err = eVal;
        setErr(newErr);
      }
    }
    return Object.values(err).some((x) => x.err);
  }
  function handleChange(id, val) {
    switch (id) {
      case 'name':
        setName(val);
        setErr({ ...err, name: { ...err.name, err: false } });
        break;
      default:
        break;
    }
    clearError();
  }
  function onSave(e) {
    e.preventDefault();
    if (!checkError() && typeof onSubmit === 'function') {
      const file = document.getElementById('file')?.files?.[0];

      onSubmit({
        name,
        file,
        siteId: site,
      });
    }
  }
  const data = doc?.readBy
    ? doc.readBy.map((d) => ({
        Namn: `${d.firstName} ${d.lastName}`,
        Godkändes: new Date(d.readAt).toDateString(),
      }))
    : [];
  const manyUsers = data.length > 10;
  return (
    <div className={styles.container}>
      <form className={styles.flexColumn} onSubmit={onSave}>
        <div className={styles.section}>
          <TextInput
            label="Namn"
            value={name}
            onChange={(e) => handleChange('name', e.target.value)}
            error={err.name.err}
            helperText={err.name.err ? err.name.text : null}
          />
        </div>

        <Divider light />
        <div className={styles.section}>
          <Input
            disableUnderline
            type="file"
            id="file"
            name="file"
            inputProps={{
              accept: 'image/png,image/jpeg,.pdf',
            }}
          />
        </div>

        <div className={styles.selects}>
          <FormControl
            variant="outlined"
            className={styles.select}
            margin="dense"
            display="flex"
          >
            <InputLabel id="site">Anläggning</InputLabel>
            <Select
              labelId="site"
              id="site-select"
              margin="dense"
              value={site}
              onChange={(e) => setSite(e.target.value)}
            >
              {(sites || []).map((s) => (
                <MenuItem key={s.id} value={s.id}>
                  {s.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Divider light />
        </div>
        {doc && data && (
          <Table
            data={data}
            noPagination={!manyUsers}
            hideSearch={!manyUsers}
            searchPlaceholder="Sök efter användare"
            rowClass={styles.row}
          />
        )}

        <div>
          <div className={styles.footer}>
            <SaveButton type="submit">Spara dokument</SaveButton>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Document;
