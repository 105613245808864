import moment from 'moment';
import createStore from '../utils/create-store';

export const RecordingsStore = createStore({
  datesWithRecordings: [],
  segments: [],
  date: moment().format('YYYY-MM-DD'),
  bucket: null,
  exportingVideo: false,
  temporaryLink: null,
});

export default new RecordingsStore();
