import timeoutStore from '../stores/TimeoutStore';
import { del, get, post } from '../utils/api';
import { createSnack } from './SnackActions';

export function getTimeoutCodes(siteId) {
  timeoutStore.isFetching = true;
  return get(`/timeout/tmp?siteId=${siteId}`)
    .then((res) => {
      const { codes } = res.data;
      timeoutStore.timeoutCodes = codes;
      timeoutStore.isFetching = false;
    })
    .catch(() => createSnack('Kude inte hämta de temporära koderna'));
}

export function getTimeoutCode(id) {
  return get(`/timeout/tmp/${id}`)
    .then((res) => {
      const { timeout } = res.data;
      timeoutStore.timeout = timeout;
    })
    .catch(() => createSnack('Kude inte hämta de temporära koderna'));
}

export function createTimeoutCode(siteId, phoneNbr, duration) {
  return post('/timeout/tmp', { siteId, phoneNbr, duration });
}

export function deleteTimeoutCode(id) {
  return del(`/timeout/tmp/${id}`).catch(() =>
    createSnack('Kunde inte ta bort den temporära koden')
  );
}

export function getTimeoutStatus(siteId) {
  return get(`/timeout/status/${siteId}`).then((res) => {
    const { timeout } = res.data;
    timeoutStore.timeoutStatus = timeout;
  });
}

export function forceTimeoutDeactivation(siteId) {
  return post(`/timeout/${siteId}/force`)
    .then((res) => {
      const { timeout } = res.data;
      timeoutStore.timeoutStatus = timeout;
      createSnack('Anläggningens larmstatus är återställd');
    })
    .catch(() => createSnack('Kunde inte återställa anläggningens larmstatus'));
}

export function activateTimeout(siteId) {
  return post(`/timeout/on/${siteId}`)
    .then((res) => {
      const { timeout } = res.data;
      timeoutStore.timeoutStatus = timeout;
      createSnack('Anläggningens larm är nu avstängt');
    })
    .catch(() => createSnack('Kunde inte pausa larmet'));
}

export function deactivateTimeout(siteId) {
  return post(`/timeout/off/${siteId}`)
    .then((res) => {
      const { timeout } = res.data;
      timeoutStore.timeoutStatus = timeout;
      createSnack('Anläggningens larm är nu återställt');
    })
    .catch(() => createSnack('Kunde inte återställa anläggningens larm'));
}

export function clearTimeoutCodes() {
  timeoutStore.timeoutCodes = [];
}

export function getTimeoutLogs(
  siteId,
  { offset = 0, limit = 100, fromMs, toMs } = {}
) {
  let params = { siteId, offset, limit };
  if (fromMs) params.fromMs = fromMs;
  if (toMs) params.toMs = toMs;
  params = Object.keys(params)
    .map((x) => `${x}=${params[x]}`)
    .join('&');
  return get(`/timeout/logs?${params}`)
    .then((res) => {
      const { logs, total } = res.data;
      if (offset > 0 && logs?.[0]?.id > timeoutStore.logs?.[0]?.id)
        timeoutStore.logs = timeoutStore.logs.concat(logs);
      else timeoutStore.logs = logs;
      timeoutStore.logTotal = total;
    })
    .catch(() => createSnack('Kunde inte hämta loggarna för larmpaus'));
}

export function getTimeoutIntervals(siteId, { fromMs, toMs } = {}) {
  let params = { siteId };
  if (fromMs) params.fromMs = fromMs;
  if (toMs) params.toMs = toMs;
  params = Object.keys(params)
    .map((x) => `${x}=${params[x]}`)
    .join('&');
  return get(`/timeout/intervals?${params}`)
    .then((res) => {
      timeoutStore.intervals = res.data?.intervals || [];
    })
    .catch(() => createSnack('Kunde inte hämta larmpaus'));
}
