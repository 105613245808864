import createStore from '../utils/create-store';

const _SnackStore = createStore({
  snacks: [],
});

export class SnackStore extends _SnackStore {
  constructor() {
    super();
    setInterval(() => {
      this.snacks$.next(
        this.snacks$.value.filter((x) => Date.now() - x.created < 6000)
      );
    }, 1000);
  }

  addSnack(msg) {
    const o = {
      msg,
      created: Date.now(),
    };
    // Only one snack for now
    this.snacks$.next([o]);
  }

  clear() {
    this.snacks$.next([]);
  }
}

export default new SnackStore();
