import createStore from '../utils/create-store';

export const AuthStore = createStore({
  usesTfa: false,
  otpUrl: '',
  verified: false,
  manCode: '',
});

export default new AuthStore();
