import moment from 'moment';

moment.updateLocale('sv');

export function timeUtcToLocal(utc) {
  return moment.utc(parseInt(utc, 10)).local().toDate();
}

export function timeLocalToUtc(local) {
  return moment(parseInt(local, 10)).utc().toDate();
}
