import documentStore from '../stores/DocumentStore';
import { createSnack } from './SnackActions';
import { get, post, put, del } from '../utils/api';

export async function getDocuments(siteId) {
  documentStore.isFetching = true;
  return get(`/documents?siteId=${siteId || 0}`)
    .then((res) => {
      let { data } = res.data;
      documentStore.documents = data || [];
    })
    .catch(() => {
      createSnack('Kunde inte hämta dokument');
      documentStore.documents = [];
    })
    .finally(() => {
      documentStore.isFetching = false;
    });
}

export async function getDocumentData(id, siteId) {
  if (!id) return;
  return get(`/documents/data/${id}?siteId=${siteId}`, { responseType: 'blob' })
    .then((res) => {
      let data = res.data || {};
      documentStore.docData = data;
    })
    .catch(() => {
      createSnack('Kunde inte hämta detta dokuments data');
      documentStore.docData = null;
    });
}

export async function getDocument(id, siteId) {
  if (!id) return;
  return get(`/documents/${id}?siteId=${siteId}`)
    .then((res) => {
      let { doc } = res.data || {};
      documentStore.doc = doc;
    })
    .catch(() => {
      createSnack('Kunde inte hämta detta dokument');
      documentStore.doc = null;
    });
}

export async function addDocument({ name, file, siteId }) {
  return post(
    `/documents/?siteId=${siteId}&name=${name}&type=${file.type}`,
    file,
    {
      headers: {
        'Content-Type': file.type,
      },
    }
  )
    .then(() => createSnack('Dokument skapat'))
    .catch((e) => {
      createSnack(`Kunde inte skapa dokument '${name}'`);
      throw e;
    });
}

export async function updateDocument({ id, name, file, siteId }) {
  return put(`/documents/${id}?siteId=${siteId}`, file, {
    params: {
      name,
      type: file?.type,
    },
    headers: {
      'Content-Type': file?.type,
    },
  })
    .then(() => createSnack('Dokument uppdaterat'))
    .catch((e) => {
      createSnack(`Kunde inte uppdatera dokument '${name}'`);
      throw e;
    });
}

export async function deleteDocument(id, siteId) {
  return del(`/documents/${id}?siteId=${siteId}`)
    .then(() => createSnack('Dokument raderat'))
    .catch(() => createSnack('Kunde inte radera dokument'));
}

export async function setDocumentRead(id, siteId, read) {
  return post(`/document-read/${id}?siteId=${siteId}`, {
    read,
  })
    .then(() => createSnack(`Dokument markerat som ${read ? '' : 'o'}läst`))
    .catch(() =>
      createSnack(`Kunde inte markera dokument som ${read ? '' : 'o'}läst`)
    );
}

export function clearDocuments() {
  documentStore.documents = [];
}

export function clearDocument() {
  documentStore.doc = null;
}

export function clearDocumentData() {
  documentStore.docData = null;
}
