import React from 'react';
// import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import styles from './Buttons.module.css';

export default function DefaultButton({
  admin = false,
  children,
  variant,
  disabled,
  defaultClass,
  href,
  className,
  ...props
}) {
  const button = (
    <Button
      className={classNames(styles.button, {
        [defaultClass]: !admin && defaultClass,
        [className]: !!className,
        [styles.primary]: admin,
        [styles.disabled]: disabled,
      })}
      variant={variant || 'contained'}
      disabled={disabled}
      {...props}
    >
      {children}
    </Button>
  );

  if (href) {
    return (
      <Link className={styles.link} to={href}>
        {button}
      </Link>
    );
  }
  return button;
}

export function SaveButton({ children = 'Spara', ...props }) {
  return (
    <DefaultButton
      defaultClass={styles.secondary}
      {...props}
      children={children}
    />
  );
}

export function AddButton({ children = 'Lägg till', ...props }) {
  return (
    <DefaultButton
      defaultClass={styles.secondary}
      startIcon={<AddIcon />}
      {...props}
      children={children}
    />
  );
}

export function UndoButton({ ...props }) {
  return (
    <DefaultButton
      defaultClass={styles.outlined}
      variant="outlined"
      {...props}
    />
  );
}
