import React, { useState, useEffect } from 'react';
import { Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import snackStore from '../stores/SnackStore';

function SnackbarComponent() {
  const [snacks, setSnacks] = useState([]);
  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    snackStore.clear();
  }
  useEffect(() => {
    const subscription = snackStore.snacks$.subscribe((data) => {
      setSnacks(data);
    });

    return () => subscription.unsubscribe();
  }, []);

  if (!snacks) return null;
  return (
    <>
      {snacks.map((snack, idx) => (
        <Snackbar
          key={idx}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          message={snack.msg}
          open={true}
          action={
            <>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        />
      ))}
    </>
  );
}

export default SnackbarComponent;
