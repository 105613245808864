import createStore from '../utils/create-store';

const TimeoutStore = createStore({
  timeoutCodes: [],
  timeout: null,
  timeoutStatus: null,
  isFetching: false,
  logs: [],
  logTotal: 0,
  intervals: [],
});
export default new TimeoutStore();
