import React, { useEffect, useState } from 'react';
import { NavLink, Redirect, useLocation } from 'react-router-dom';
import { Tab, Tabs as BaseTabs } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SiteSelector from './SiteSelector';
import styles from './SubHeader.module.css';

const Tabs = withStyles({
  indicator: { borderBottom: '3px solid var(--dark-red)' },
})(BaseTabs);

function SubHeader({ links, showSiteSelector = true }) {
  const [val, setVal] = useState(0);
  const location = useLocation();

  useEffect(() => {
    let i = (links || []).findIndex((c) =>
      location?.pathname?.startsWith(c?.to)
    );
    if (i > -1) setVal(i);
  }, [location?.pathname, links]);

  if (
    links?.length > 0 &&
    !links.map((x) => x.to).find((x) => location.pathname.startsWith(x))
  ) {
    return <Redirect to={links[0].to} />;
  }

  return (
    <header className={styles.container}>
      <div className={styles.containerInner}>
        {showSiteSelector && <SiteSelector className={styles.siteSelector} />}
        <Tabs
          className={styles.menu}
          scrollButtons="on"
          variant="scrollable"
          value={val}
          onChange={(e, v) => setVal(v)}
        >
          {(links || []).map(({ to, title }, idx) => (
            <Tab
              className={styles.link}
              key={idx}
              label={title}
              value={idx}
              component={NavLink}
              to={to}
              activeClassName={styles.active}
            />
          ))}
        </Tabs>
      </div>
    </header>
  );
}
export default SubHeader;
