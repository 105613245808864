import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import PermissionGroupList from './PermissionGroupList';
import Sidebar from '../../Sidebar';
import Loading from '../../Loading';
import { SaveButton, AddButton } from '../../Buttons';
import {
  getPermissionGroups,
  clearPermissionGroups,
} from '../../../actions/PermissionGroupActions';
import permissionGroupStore from '../../../stores/PermissionGroupStore';
import withState from '../../../utils/with-state';
import styles from '../../configuration/Configuration.module.css';

function EmptyContainer() {
  return (
    <>
      <Sidebar
        title="Administrera behörighetsgrupper här"
        text="Administrera behörighetsgrupper här"
        Icon={<EventBusyIcon fontSize="large" />}
      />
      <div className={styles.content}>
        <div className={styles.header}>
          <h3>Alla behörighetsgrupper</h3>
        </div>
        <div className={styles.empty}>
          <h2 className={styles.emptyTitle}>
            Du har för närvarande inga inlagda behörighetsgrupper
          </h2>
          <div className={styles.iconCircle}>
            <EventBusyIcon className={styles.emptyIcon} />
          </div>
          <div>
            <SaveButton href="/admin/permission-groups/new">
              Skapa behörighetsgrupp
            </SaveButton>
          </div>
        </div>
      </div>
    </>
  );
}

function PermissionGroupConfiguration({ permissionGroups }) {
  const [edit, setEdit] = useState(null);

  useEffect(() => {
    clearPermissionGroups();
    setEdit(null);
    getPermissionGroups();
  }, []);
  if (!permissionGroups) return <Loading big center />;

  if (edit) return <Redirect to={`/admin/permission-groups/${edit}`} />;

  return (
    <div className={styles.container}>
      <div className={styles.containerInner}>
        {permissionGroups?.length === 0 && <EmptyContainer />}

        {permissionGroups?.length > 0 && (
          <>
            <Sidebar
              title="Organisera och skapa behörighetsgrupper"
              text=""
              Icon={<EventBusyIcon fontSize="large" />}
            />
            <div className={styles.content}>
              <div className={styles.header}>
                <h3>Alla behörighetsgrupper</h3>
                <AddButton href="/admin/permission-groups/new">
                  Skapa ny
                </AddButton>
              </div>
              <div className={styles.paper}>
                <PermissionGroupList
                  permissionGroups={permissionGroups || []}
                  selected={edit}
                  onSelect={setEdit}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default withState(
  PermissionGroupConfiguration,
  permissionGroupStore,
  (state) => ({
    permissionGroups: state.permissionGroups,
  })
);
