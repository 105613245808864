import React, { useState } from 'react';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import { Link } from 'react-router-dom';
import { DeleteOutline } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import ConfirmDialog from '../../configuration/ConfirmDialog';
import Table from '../../Table';
import {
  getPermissionGroups,
  deletePermissionGroup,
} from '../../../actions/PermissionGroupActions';
import { trimStr } from '../../../utils/generation';
import aStyles from '../../configuration/List.module.css';
import moStyles from '../../mouseOver.module.css';

const styles = { ...aStyles, ...moStyles };

function PermissionGroupList({ permissionGroups }) {
  const [toDelete, setToDelete] = useState(null);
  async function onDeletePermissionGroup(id) {
    await deletePermissionGroup(id);
    setToDelete(null);
    getPermissionGroups();
  }
  const data = (permissionGroups || []).map((s) => ({
    Namn: trimStr(s.name),
    ' ': (
      <div className={styles.mouseOver}>
        <Link className={styles.link} to={`/admin/permission-groups/${s.id}`}>
          <IconButton>
            <VisibilityIcon />
            <p className={styles.mouseOverText}>Titta & redigera</p>
          </IconButton>
        </Link>
        <IconButton className={styles.link} onClick={() => setToDelete(s)}>
          <DeleteOutline />
          <p className={styles.mouseOverText}>Ta bort</p>
        </IconButton>
      </div>
    ),
  }));
  return (
    <div className={styles.container}>
      {(!data || data.length === 0) && (
        <p className={styles.noEntryText}>
          Du har för närvarande inga inlagda roller
        </p>
      )}
      {data.length > 0 && (
        <Table
          data={data}
          showSearch={true}
          searchPlaceholder="Sök efter roll"
          rowClass={styles.row}
        />
      )}
      {toDelete && (
        <ConfirmDialog
          open={toDelete !== null}
          title="Bekräfta borttagning"
          text={`Är du säker på att du vill ta bort '${toDelete.name}'?`}
          onDeny={() => setToDelete(null)}
          onConfirm={() => onDeletePermissionGroup(toDelete.id)}
        />
      )}
    </div>
  );
}

export default PermissionGroupList;
