import crypto from 'crypto';
// eslint-disable-next-line import/prefer-default-export
export async function requestNewAccessCode() {
  return crypto.randomBytes(4).toString('hex');
}

const PREVIEW_LENGTH = 50;
export const trimStr = (str) =>
  `${str.slice(0, Math.min(str.length, PREVIEW_LENGTH))}${
    str.length > PREVIEW_LENGTH ? '...' : ''
  }`;
