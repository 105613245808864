import { useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';

import { GROUP_MAP, VERB_MAP } from '../../constants';
import styles from './PermissionGroupDialog.module.css';

export default function PermissionGroupDialog({
  open,
  onClose,
  permissionGroup = null,
}) {
  const permissions = useMemo(() => {
    if (!permissionGroup) return null;
    return Object.keys(permissionGroup.permissions).filter(
      (x) => permissionGroup.permissions[x]
    );
  }, [permissionGroup]);

  const groupedPermissions = useMemo(() => {
    if (!permissions) return null;

    return permissions.reduce((acc, cur) => {
      const words = cur
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .split(' ')
        .map((x) => x.toLowerCase());
      const verb = words[words.length - 1];
      const key = words.slice(0, words.length - 1).join(' ');

      acc[key] = acc[key] || {};
      acc[key][verb] = cur;
      return acc;
    }, {});
  }, [permissions]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Behörighetsgrupp: {permissionGroup?.name}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {groupedPermissions && (
            <div className={styles.inner}>
              {Object.keys(groupedPermissions).map((key) => (
                <div key={key} className={styles.group}>
                  <h3 className={styles.groupHeader}>{GROUP_MAP[key]}</h3>
                  <div className={styles.groupPermissions}>
                    {Object.keys(groupedPermissions[key]).map((verb) => (
                      <span key={verb}>{VERB_MAP[verb]}</span>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
